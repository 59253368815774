import styled from "styled-components";
import { COLORS, LIGHT_THEME_COLORS, MAP_STATUS_COLORS } from "styles/colors";
import { FLEX } from "styles/flex";
import { calcRem } from "styles/theme";
import { FaCircle } from "react-icons/fa";
import {
  DashboardAlertData,
  DashboardData,
} from "api/interfaces/dashboardInterface.interface";
import RecorderInfoView from "./RecorderInfoView";
import Popover from "antd/lib/popover";

import MARKWE_ALERT from "assets/icons/map/marker_alert.svg";
import MARKWE_ASSIGN from "assets/icons/map/marker_assign.svg";
import MARKWE_HEALTHY from "assets/icons/map/marker_healthy.svg";
import MARKWE_WARNING from "assets/icons/map/marker_warning.svg";
import MARKWE_CRITICAL from "assets/icons/map/marker_critical.svg";
import WebAppLink from "components/blocks/link/WebAppLink";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { useAppSelector } from "redux/hooks";
import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { tabletMin } from "components/MediaQuery";

interface InfoViewProps {
  system: DashboardData;
  onSelectRecorder: (system: DashboardData) => void;
}

export default function RecorderListInfoItem({
  system,
  onSelectRecorder,
}: InfoViewProps): JSX.Element {
  const isNotMobile = useMediaQuery({ minWidth: tabletMin });
  const theme = useAppSelector((state) => state.theme.theme);

  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );

  // recorder 개수 여러개일때
  const isMultiRecorders = system.recorders.length >= 1;
  const getStatus = (system: DashboardData) => {
    if (system.critical.length > 0) return "CRITICAL";
    if (system.alert.length > 0) return "ALERT";
    return "HEALTHY";
  };

  const tmpStatus = getStatus(system);

  const recorderItem = (rec: DashboardData) => {
    const tmpStatus2 = getStatus(rec);
    return (
      <li
        onClick={() => onSelectRecorder(rec)}
        key={`recorder-item-${rec.recorderId}`}
      >
        <FaCircle size={8} color={MAP_STATUS_COLORS[tmpStatus2]} />
        <span>{rec.name}</span>
      </li>
    );
  };

  return system.recorders.length === 1 ? (
    <Popover
      trigger="hover"
      placement="right"
      content={isNotMobile && <RecorderInfoView system={system.recorders[0]} />}
      style={{
        padding: 0,
      }}
      zIndex={980}
      color={
        theme === "light" ? LIGHT_THEME_COLORS.FILTERITEM : COLORS.PRIMARY1
      }
    >
      <Item
        key={`recorder-list-map-view-${system.recorderId}`}
        onClick={() => onSelectRecorder(system)}
        isMultiRecorders={isMultiRecorders}
      >
        <div className="left-info">
          <div className="system-alert-marker">
            <img
              src={
                tmpStatus === "HEALTHY"
                  ? MARKWE_HEALTHY
                  : tmpStatus === "ALERT"
                  ? MARKWE_ALERT
                  : MARKWE_CRITICAL
              }
              alt={`marker-${tmpStatus}`}
            />
          </div>

          <div className="system-info">
            <div className="system-title">
              <h5>{system.mergedSystemName}</h5>
              <div className="web-app-link-wrapper">
                <WebAppLink
                  type={system.type}
                  recorderId={system.recorderId}
                  mergedSystemId={system.mergedSystemId}
                  accountId={selectedAccount.accountId}
                  cloudSystemId={system.cloudSystemId}
                />
              </div>
            </div>

            {system.type === "Spectrum" && system.recorders && (
              <ul className="spectrum-system-ul">
                {system.recorders.map((rec: DashboardData, index: number) => {
                  return recorderItem(rec);
                })}
              </ul>
            )}
          </div>
        </div>
      </Item>
    </Popover>
  ) : (
    <Item
      key={`recorder-list-map-view-${system.recorderId}`}
      isMultiRecorders={isMultiRecorders}
    >
      <div className="left-info">
        <div className="system-alert-marker">
          <img
            src={
              tmpStatus === "HEALTHY"
                ? MARKWE_HEALTHY
                : tmpStatus === "ALERT"
                ? MARKWE_ALERT
                : MARKWE_CRITICAL
            }
            alt={`marker-${tmpStatus}`}
          />
        </div>

        <div className="system-info">
          <div className="system-title">
            <h5>{system.mergedSystemName}</h5>
            <div className="web-app-link-wrapper">
              <WebAppLink
                type={system.type}
                recorderId={system.recorderId}
                mergedSystemId={system.mergedSystemId}
                accountId={selectedAccount.accountId}
                cloudSystemId={system.cloudSystemId}
              />
            </div>
          </div>

          {system.type === "Spectrum" && system.recorders && (
            <ul className="spectrum-system-ul">
              {system.recorders.map((rec: DashboardData, index: number) => {
                return (
                  <Popover
                    key={`popover-${index}`}
                    trigger="hover"
                    placement="right"
                    content={<RecorderInfoView system={rec} />}
                    zIndex={980}
                    style={{
                      padding: 0,
                    }}
                    color={theme === "light" ? COLORS.WHITE : "#0d1119"}
                  >
                    {recorderItem(rec)}
                  </Popover>
                );
              })}
            </ul>
          )}
        </div>
      </div>
    </Item>
  );
}

const Item = styled.li<{ isMultiRecorders: boolean }>`
  ${FLEX.FlexBetweenStart};
  gap: 6px;
  color: ${({ theme }) => theme.COLORS.WHITE};
  white-space: nowrap;
  width: 100%;
  border-bottom: 1px solid #cacaca;
  padding: 8px 0;
  flex: 1;
  ${({ isMultiRecorders }) =>
    !isMultiRecorders &&
    `
      cursor: pointer;
    `}
  .left-info {
    ${FLEX.FlexStartStart};
    gap: 6px;
    flex: 1;
    width: 100%;
    max-width: 100%;
    overflow: hidden;
  }

  .system-alert-marker {
    flex: 0 0 auto;
    width: 15px;
    img {
      width: 100%;
    }
  }

  .system-info {
    ${FLEX.FlexStartStart};
    flex-direction: column;
    gap: 6px;
    width: 100%;
    overflow: hidden;
  }

  .system-title {
    width: 100%;
    ${FLEX.FlexStartStart};
    gap: 4px;

    h5 {
      font-weight: 400;
      font-size: ${calcRem(16)};
      width: 100%;
      max-width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .spectrum-system-ul {
    width: 100%;
    ${FLEX.FlexStartStart};
    flex-direction: column;
    flex: 1;
    overflow: hidden;

    li {
      width: 100%;
      ${FLEX.FlexStartCenter};
      flex: 1;
      gap: 4px;
      font-size: ${calcRem(12)};
      ${({ isMultiRecorders }) =>
        isMultiRecorders &&
        `
        cursor: pointer;
        `}

      span {
        overflow: hidden;
        text-overflow: ellipsis;
        flex: 1;
      }
    }
  }

  .web-app-link-wrapper {
    flex: 0 0 auto;
    .web-app-link {
      font-size: ${calcRem(11)};
    }
  }
`;
