import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { useRef, useEffect, useState, Fragment } from "react";

import * as s from "./HeaderNavBarStyled";
import UserProfile from "./sub/UserProfile";
import * as mediaQuery from "components/MediaQuery";
import type {
  RoutesProp,
  RoutesProps,
} from "api/interfaces/menuInterface.interface";
import { isNil } from "lodash";
import {
  LEVEL_TYPE,
  ProfileAccountInfo,
} from "api/interfaces/accountInterface.interface";
import { useAppSelector } from "redux/hooks";
import { checkTrialShowMenu } from "utils/AccountUtil";
import { MdMenu } from "react-icons/md";
import { ScreenRecorder, useScreenRecorder } from "../screenrecorder";
import NormalConfirmModal from "components/templates/modal/small/confirm/normal";

type HeaderNavBarProps = {
  menu?: Array<RoutesProps>;
  buttonRef?: any;
  onOpen?: () => void;
  onMenuClick?: (index: number) => void;
  onChangeSwitch: () => void;
};

function HeaderNavBar({
  menu,
  buttonRef,
  onOpen,
  onMenuClick,
  onChangeSwitch,
}: HeaderNavBarProps): JSX.Element {
  // console.log(menu);
  const navigate = useNavigate();
  const location = useLocation();
  const horizontalScrollRef = useRef<any>(null);

  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );
  const [confirm, setConfirm] = useState<JSX.Element | null>(null);

  const screenRecorderHookProps = useScreenRecorder();
  const {
    startRecording: startScreenRecording,
    stopRecording: stopScreenRecording,
    preconfirmed: screenRecordingPreconfirmed,
    setPreconfirmed: setScreenRecordingPreconfirmed,
  } = screenRecorderHookProps;
  const [isScreenRecording, setIsScreenrecording] = useState<boolean>(false);

  let submenu: Array<RoutesProp> | [] = isNil(menu) ? [] : menu[0].children;

  function handleFinishScreenRecording() {
    setIsScreenrecording(false);
  }

  function handleStopScreenRecording() {
    stopScreenRecording();
  }

  function confirmScreenRecording() {
    if (isScreenRecording) {
      return;
    }
    if (screenRecordingPreconfirmed) {
      setIsScreenrecording(true);
      startScreenRecording();
      return;
    }
    const confirmModal = (
      <NormalConfirmModal
        label={"screenRecording"}
        onConfirm={(dontRepeat?: boolean) => {
          if (dontRepeat) {
            setScreenRecordingPreconfirmed(true);
          }
          setIsScreenrecording(true);
          startScreenRecording();
          setConfirm(null);
        }}
        onConfirmCancel={() => setConfirm(null)}
      />
    );
    setConfirm(confirmModal);
  }

  return (
    <s.HeaderContainer level={selectedAccount.accountLevel}>
      <s.Inner>
        <mediaQuery.Mobile>
          <button onClick={onOpen} className="sidebar-collapse-button">
            <MdMenu size={26} />
          </button>
        </mediaQuery.Mobile>
        <s.LeftWrapper>
          <s.Title
            onClick={() => window.location.reload()}
            level={selectedAccount.accountLevel}
          >
            {isNil(menu) ? "" : menu[0].name}
          </s.Title>
          {menu && (
            <s.MenuContainer ref={horizontalScrollRef}>
              {submenu?.map((item: RoutesProp, index: number) => {
                if (
                  (item.routeName === "/manager/sitenotices" ||
                    item.routeName === "/manager/emails") &&
                  selectedAccount.accountLevel !== LEVEL_TYPE.DW
                )
                  return null;

                if (
                  item.routeName === "/help/techsupport" &&
                  selectedAccount.accountLevel === LEVEL_TYPE.DW
                )
                  return null;

                if (
                  (item.routeName === "/manager/sitenotices" ||
                    item.routeName === "/manager/emails") &&
                  selectedAccount.accountLevel === LEVEL_TYPE.DW &&
                  matchMedia(
                    `screen and (max-width: ${mediaQuery.tabletMin}px)`
                  ).matches
                )
                  return null;

                if (
                  item.routeName === "/settings/license" &&
                  (selectedAccount.accountLevel !== LEVEL_TYPE.EU ||
                    !selectedAccount.isAdmin)
                )
                  return null;

                if (
                  item.routeName === "/settings/adminAccount" &&
                  !selectedAccount.isAdmin
                )
                  return null;
                if (
                  item.routeName === "/dashboard/map" &&
                  selectedAccount.accountLevel === LEVEL_TYPE.DW
                )
                  return null;

                if (
                  item.routeName === "/dashboard/admin" &&
                  !selectedAccount.isAdmin &&
                  !selectedAccount.isHidden
                )
                  return null;
                if (
                  item.routeName === "/dashboard/chart" &&
                  (selectedAccount.accountLevel === LEVEL_TYPE.DW ||
                    (!selectedAccount.isAdmin && !selectedAccount.isHidden))
                )
                  return null;

                if (
                  selectedAccount.accountLevel === LEVEL_TYPE.EU &&
                  checkTrialShowMenu(selectedAccount) &&
                  (item.routeName === "/users/groups" ||
                    item.routeName === "/recorders/groups")
                )
                  return null;

                return (
                  <s.MenuItem
                    level={selectedAccount.accountLevel}
                    key={index}
                    isCurrent={location.pathname.includes(
                      item.routeName ? item.routeName : ""
                    )}
                    // isCurrent={location.pathname === item.routeName}
                    onClick={() => {
                      if (onMenuClick) onMenuClick(index);
                      if (item.routeName) {
                        navigate({
                          pathname: item.routeName,
                          search: `?${createSearchParams({
                            account: selectedAccount.accountId,
                          })}`,
                        });
                        // if(item.routeName.includes("/account") ||
                        //     item.routeName.includes("/billing") ||
                        //     item.routeName.includes("/recorders/list")
                        // ){
                        //   navigate(item.routeName+`/${selectedAccount.accountId}`);
                        // }else{
                        //   navigate({
                        //     pathname : item.routeName,
                        //     search : `?${createSearchParams({
                        //       account:selectedAccount.accountId
                        //     })}`
                        //     }
                        //   );
                        // }
                      }
                    }}
                  >
                    {item.name}
                  </s.MenuItem>
                );
              })}
            </s.MenuContainer>
          )}
          {/* <mediaQuery.Mobile>
            {isHelp && (
              <s.SlideButton onClick={() => handleNextButtonClick("next")}>
                <img src={CHEVRON_RIGHT} alt="chevron right icon" />
              </s.SlideButton>
            )}
          </mediaQuery.Mobile> */}
        </s.LeftWrapper>
        <mediaQuery.Default>
          <s.RightContainer>
            {/* {<RoutesMark />} */}
            {isScreenRecording && (
              <ScreenRecorder
                hookProps={screenRecorderHookProps}
                onStop={handleStopScreenRecording}
                onFinish={handleFinishScreenRecording}
              />
            )}
            <UserProfile
              ref={buttonRef}
              onChangeSwitch={onChangeSwitch}
              onConfirmScreenRecording={confirmScreenRecording}
              isScreenRecording={isScreenRecording}
            />
          </s.RightContainer>
        </mediaQuery.Default>
      </s.Inner>
      {confirm}
    </s.HeaderContainer>
  );
}

export default HeaderNavBar;
