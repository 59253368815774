import { Table } from "components/atoms/table/Table";
import { Media, SortOrder, TableColumn } from "react-data-table-component";
import {
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { HistoryDetailAlert } from "api/interfaces/alertInterface.interface";
import TableColumnFilterTitle from "components/blocks/table/TableColumnFilterTitle";
import { substractTime } from "utils/functions";
import TableButton from "components/atoms/buttons/TableButton";
import { useCallback, useEffect, useState } from "react";
import { getAlertHistoryListDetail } from "api/alertAPI";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { useQuery } from "react-query";
import {
  BasePagination,
  SORT_DIRECTION,
} from "api/interfaces/commonInterface.interface";
import { getRecorderTimeFormat } from "utils/timeUtil";
import { notify } from "components/atoms/notification/Notification";
import moment from "moment";
import {
  AlertCategoryTypeFilter,
  AlertStatusTypeFilter,
  displayAlert,
  navigateAlertDetailPage,
} from "utils/AlertUtil";
import { useIntl } from "react-intl";
import * as mediaQuery from "components/MediaQuery";
import { queryNavigate } from "utils/MenuUtil";
import { StatusTxt } from "pages/alert/AlertStyled";

type Props = {};

export default function AlertHistoryDetail(props: Props): JSX.Element {
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );

  const [urlSearchParams] = useSearchParams();

  const recorderId = urlSearchParams.get("recorderId");
  const {
    alertHistoryDetailQueryInfo,
    setAlertHistoryDetailQueryInfo,
    listTotal,
    setListTotal,
    isPending,
    setIsPending,
  } = useOutletContext<any>();

  const [data, setData] = useState<HistoryDetailAlert[]>([]);

  const alertDetailQuery = useQuery(
    [
      "alertHistoryDetail",
      alertHistoryDetailQueryInfo,
      selectedAccount.accountId,
    ],
    () =>
      getAlertHistoryListDetail({
        accountId: selectedAccount.accountId,
        recorderId: recorderId as string,
        payload: alertHistoryDetailQueryInfo,
      }),
    {
      retry: 0,
      enabled: recorderId !== undefined && selectedAccount.accountId !== "",
      onSuccess: (res: any) => {
        console.log(res.result, 5645465);
        if (
          res.error !== undefined &&
          res.error === 0 &&
          res.result !== undefined
        ) {
          setData(res.result.alerts);
          setListTotal(res.page.total);
        } else {
          setData([]);
          setListTotal(0);
        }
      },
      onError: (e: any) => {
        setData([]);
        setListTotal(0);
        notify(
          "error",
          intl.formatMessage({
            id: "label.alertHistory.notify.search.fail",
            defaultMessage: "Alert history search error.",
          })
        );
      },
      onSettled: (e: any) => {
        setIsPending(false);
      },
    }
  );

  const onClickAlertCategoryType = useCallback(
    (value: string | undefined) => {
      if (value !== undefined) {
        if (value === "all") {
          if (alertHistoryDetailQueryInfo.category !== null) {
            queryNavigate(navigate, location.pathname, {
              ...(recorderId !== null && { recorderId }),
              pageLimit: alertHistoryDetailQueryInfo.pageLimit,
              pageNum: 0,
              keyword: alertHistoryDetailQueryInfo.keyword as string,
              sortType: alertHistoryDetailQueryInfo.sortType,
              sortDirection:
                alertHistoryDetailQueryInfo.sortDirection as SORT_DIRECTION,
              category: null,
              status: alertHistoryDetailQueryInfo.status,
            });
          } else {
            alertDetailQuery.refetch();
          }
          setIsPending(true);
          // setAlertHistoryDetailQueryInfo((info) => {
          //   return { ...info, category: null, pageNum: 0 };
          // });
        } else {
          if (alertHistoryDetailQueryInfo.category !== value) {
            queryNavigate(navigate, location.pathname, {
              ...(recorderId !== null && { recorderId }),
              pageLimit: alertHistoryDetailQueryInfo.pageLimit,
              pageNum: 0,
              keyword: alertHistoryDetailQueryInfo.keyword as string,
              sortType: alertHistoryDetailQueryInfo.sortType,
              sortDirection:
                alertHistoryDetailQueryInfo.sortDirection as SORT_DIRECTION,
              category: value,
              status: alertHistoryDetailQueryInfo.status,
            });
          } else {
            alertDetailQuery.refetch();
          }
          setIsPending(true);
          // setAlertHistoryDetailQueryInfo((info) => {
          //   return { ...info, category: value as string, pageNum: 0 };
          // });
        }
      }
    },
    [
      alertDetailQuery,
      alertHistoryDetailQueryInfo.category,
      alertHistoryDetailQueryInfo.keyword,
      alertHistoryDetailQueryInfo.pageLimit,
      alertHistoryDetailQueryInfo.sortDirection,
      alertHistoryDetailQueryInfo.sortType,
      alertHistoryDetailQueryInfo.status,
      location.pathname,
      navigate,
    ]
  );

  const onClickAlertStatusType = useCallback(
    (value: string | undefined) => {
      if (value !== undefined) {
        if (value === "all") {
          if (alertHistoryDetailQueryInfo.status !== null) {
            queryNavigate(navigate, location.pathname, {
              ...(recorderId !== null && { recorderId }),
              pageLimit: alertHistoryDetailQueryInfo.pageLimit,
              pageNum: 0,
              keyword: alertHistoryDetailQueryInfo.keyword as string,
              sortType: alertHistoryDetailQueryInfo.sortType,
              sortDirection:
                alertHistoryDetailQueryInfo.sortDirection as SORT_DIRECTION,
              category: alertHistoryDetailQueryInfo.category,
              status: null,
            });
          } else {
            alertDetailQuery.refetch();
          }
          setIsPending(true);
          // setAlertHistoryDetailQueryInfo((info) => {
          //   return { ...info, status: null, pageNum: 0 };
          // });
        } else {
          if (alertHistoryDetailQueryInfo.status !== value) {
            queryNavigate(navigate, location.pathname, {
              ...(recorderId !== null && { recorderId }),
              pageLimit: alertHistoryDetailQueryInfo.pageLimit,
              pageNum: 0,
              keyword: alertHistoryDetailQueryInfo.keyword as string,
              sortType: alertHistoryDetailQueryInfo.sortType,
              sortDirection:
                alertHistoryDetailQueryInfo.sortDirection as SORT_DIRECTION,
              category: alertHistoryDetailQueryInfo.category,
              status: value,
            });
          } else {
            alertDetailQuery.refetch();
          }
          setIsPending(true);
          // setAlertHistoryDetailQueryInfo((info) => {
          //   return { ...info, status: value as string, pageNum: 0 };
          // });
        }
      }
    },
    [
      alertDetailQuery,
      alertHistoryDetailQueryInfo.category,
      alertHistoryDetailQueryInfo.keyword,
      alertHistoryDetailQueryInfo.pageLimit,
      alertHistoryDetailQueryInfo.sortDirection,
      alertHistoryDetailQueryInfo.sortType,
      alertHistoryDetailQueryInfo.status,
      location.pathname,
      navigate,
    ]
  );

  const detailMobileColumns: TableColumn<any>[] = [
    {
      name: (
        <TableColumnFilterTitle
          title="Category"
          filters={AlertCategoryTypeFilter}
          onClick={onClickAlertCategoryType}
        />
      ),
      grow: 1,
      selector: (row) => row.category,
    },
    {
      name: (
        <TableColumnFilterTitle
          title="Status"
          filters={AlertStatusTypeFilter}
          onClick={onClickAlertStatusType}
        />
      ),
      grow: 1,
      cell: (row) => (
        <StatusTxt string={row.status.toUpperCase()}>{row.status}</StatusTxt>
      ),
    },
    {
      name: "Alert",
      grow: 2,
      selector: (row) => displayAlert(row),
      // selector: (row) => row.alertType !== "Video Loss"
      // ? row.alertType
      // : row.alertType.concat(row.cameraName !== undefined &&
      //   row.cameraName === ""
      //     ? ` [ ${row.channel} ]`
      //     : ` [ ${row.cameraName} ]`),
    },
    {
      id: "alertTrigger",
      name: "Date",
      grow: 1.5,
      sortable: true,
      sortField: "alertTrigger",
      selector: (row) =>
        row.alertTriggered !== undefined
          ? getRecorderTimeFormat(
              row.alertTriggered,
              selectedAccount,
              row.timezone,
              true
            )
          : "",
    },

    // 마지막 버튼
    {
      name: "",

      cell: (row) => (
        <span className="row-click-button">
          <TableButton label="View Alert" onClickBtn={() => onRowClick(row)} />
        </span>
      ),
      right: true,
    },
  ];
  const detailColumns: TableColumn<any>[] = [
    {
      name: (
        <TableColumnFilterTitle
          title="Category"
          filters={AlertCategoryTypeFilter}
          onClick={onClickAlertCategoryType}
        />
      ),
      grow: 1,
      selector: (row) => row.category,
    },
    {
      name: "Rule Name",
      grow: 1,
      selector: (row) => row.ruleName,
    },
    {
      name: (
        <TableColumnFilterTitle
          title="Status"
          filters={AlertStatusTypeFilter}
          onClick={onClickAlertStatusType}
        />
      ),
      grow: 1,
      cell: (row) => (
        <StatusTxt string={row.status.toUpperCase()}>{row.status}</StatusTxt>
      ),
    },
    {
      name: "Alert",
      grow: 2,
      selector: (row) => displayAlert(row),
      hide: Media.SM,
      // selector: (row) => row.alertType !== "Video Loss"
      // ? row.alertType
      // : row.alertType.concat(row.cameraName !== undefined &&
      //   row.cameraName === ""
      //     ? ` [ ${row.channel} ]`
      //     : ` [ ${row.cameraName} ]`),
    },
    {
      id: "alertTrigger",
      name: "Trigger Time",
      grow: 1.5,
      sortable: true,
      sortField: "alertTrigger",
      selector: (row) =>
        row.alertTriggered !== undefined
          ? getRecorderTimeFormat(
              row.alertTriggered,
              selectedAccount,
              row.timezone,
              true
            )
          : "",
    },
    {
      id: "alertClose",
      name: "Closed Time",
      grow: 1.5,
      selector: (row) =>
        row.closedDate !== undefined
          ? getRecorderTimeFormat(
              row.closedDate,
              selectedAccount,
              row.timezone,
              true
            )
          : "",
    },
    {
      name: "Duration",
      grow: 1.2,
      selector: (row) =>
        row.status === "CLOSED" || row.status === "Closed"
          ? substractTime(row.alertTriggered, row.closedDate)
          : substractTime(row.alertTriggered, moment()),
    },
    {
      name: "Resolved By",
      grow: 1.5,
      selector: (row) => (row.resolvedUsername ? row.resolvedUsername : ""),
      sortable: true,
      hide: Media.SM,
    },
    // 마지막 버튼
    {
      name: "",
      minWidth: "150px",
      grow: Media.SM ? 1 : 5,
      cell: (row) => (
        <span className="row-click-button">
          <TableButton label="View Alert" onClickBtn={() => onRowClick(row)} />
        </span>
      ),
      right: true,
    },
  ];

  const onRowClick = (row: HistoryDetailAlert) => {
    navigateAlertDetailPage({
      location,
      navigate,
      recorderId: recorderId ?? "",
      historyId: row.alertHistoryId,
      alertType: row.alertType,
    });
  };

  const onChangePage = useCallback(
    (page: number, totalRows: number) => {
      if (page !== alertHistoryDetailQueryInfo.pageNum + 1) {
        queryNavigate(navigate, location.pathname, {
          ...(recorderId !== null && { recorderId }),
          pageLimit: alertHistoryDetailQueryInfo.pageLimit,
          pageNum: page - 1,
          keyword: alertHistoryDetailQueryInfo.keyword as string,
          sortType: alertHistoryDetailQueryInfo.sortType,
          sortDirection:
            alertHistoryDetailQueryInfo.sortDirection as SORT_DIRECTION,
          category: alertHistoryDetailQueryInfo.category,
          status: alertHistoryDetailQueryInfo.status,
        });
        setIsPending(true);
        // setAlertHistoryDetailQueryInfo((info) => {
        //   return { ...info, pageNum: (page - 1) as number };
        // });
      }
    },
    [
      alertHistoryDetailQueryInfo.category,
      alertHistoryDetailQueryInfo.keyword,
      alertHistoryDetailQueryInfo.pageLimit,
      alertHistoryDetailQueryInfo.pageNum,
      alertHistoryDetailQueryInfo.sortDirection,
      alertHistoryDetailQueryInfo.sortType,
      alertHistoryDetailQueryInfo.status,
      location.pathname,
      navigate,
    ]
  );

  const handleSort = async (
    selectedColumn: TableColumn<any>,
    sortDirection: SortOrder
  ) => {
    if (selectedColumn.sortField !== undefined) {
      setAlertHistoryDetailQueryInfo((info: BasePagination) => {
        return {
          ...info,
          sortType: selectedColumn.sortField as string,
          sortDirection: sortDirection.toUpperCase() as SORT_DIRECTION,
        };
      });
      //setIsPending(true);
    }
  };

  return (
    <>
      <mediaQuery.Default>
        <Table
          columns={detailColumns}
          data={data}
          onRowClicked={onRowClick}
          paginationTotalRows={listTotal}
          paginationPerPage={alertHistoryDetailQueryInfo.pageLimit}
          onChangePage={onChangePage}
          handleSort={handleSort}
          defaultSortFieldId={"alertTrigger"}
          defaultSortAsc={false}
          progressPending={isPending}
        />
      </mediaQuery.Default>
      <mediaQuery.Mobile>
        <Table
          columns={detailMobileColumns}
          data={data}
          //onRowClicked={onRowClick}
          paginationTotalRows={listTotal}
          paginationPerPage={alertHistoryDetailQueryInfo.pageLimit}
          onChangePage={onChangePage}
          handleSort={handleSort}
          defaultSortFieldId={"alertTrigger"}
          defaultSortAsc={false}
        />
      </mediaQuery.Mobile>
    </>
  );
}
