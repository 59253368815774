import moment, { Moment } from "moment-timezone";
import * as OsDetect from "react-device-detect";
import { Layout } from "react-grid-layout";
import { isEmpty } from "lodash";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { UserInfo } from "api/interfaces/userInterface.interface";
import { CvvItem } from "api/interfaces/reportInterface.interface";
import { notify } from "components/atoms/notification/Notification";
import { number } from "prop-types";
import { EditListPresenter } from "pages/recorders/edit/list/EditListPresenter";
import { AddReportListType } from "pages/reports/add/AddReport";
import { RecorderType } from "components/atoms/text/labels/Type";

/**
 * @param level 레벨 전체 이름
 * @returns string 레벨 축약어
 */
export const changeLevelToABB = (level: string) => {
  let abb;

  switch (level) {
    case "DW Admin":
      abb = "DW";
      break;
    case "Channel Partner":
      abb = "CP";
      break;
    case "End User":
      abb = "EU";
      break;
    default:
      return level;
  }
  return abb;
};

export const changeLevelFromABB = (level: string) => {
  let abb;

  switch (level) {
    case "DW":
      abb = "DW Admin";
      break;
    case "CP":
      abb = "Channel Partner";
      break;
    case "EU":
      abb = "End User";
      break;
    default:
      return "";
  }
  return abb;
};

/**
 * @param str : 문자
 * @description 문자열 중 앞 글자만 대문자로 변환하는 함수
 */
export const changeFirstWordToUppercase = (str: string) => {
  if (str !== undefined && str !== null)
    return str.charAt(0).toUpperCase() + str.slice(1);
  else return "";
};

/**
 *
 * @param amount number 숫자 단위 쉼표 표시
 * @returns number
 * @description 숫자 단위 00,000
 */
export const changeNumberToFormat = (number: number) => {
  if (number === null) {
    return;
  }
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

/**
 *
 * @param type report 페이지의 type
 * @returns type full name
 * @description ex. health -> Health, cvv -> CVV report , thumbnail -> Thumbnail Report
 */
export const changeTypeForReportType = (type: string) => {
  let fullType;

  switch (type) {
    case "HEALTH":
      fullType = "Health";
      break;
    case "INVENTORY":
      fullType = "Inventory";
      break;
    case "CVV":
      fullType = "CVV";
      break;
    case "THUMBNAIL":
      fullType = "Thumbnail";
      break;
    case "INSTALLATION":
      fullType = "Installation";
      break;
    case "TIMELAPSE":
      fullType = "Timelapse";
      break;
    default:
      return changeFirstWordToUppercase(type);
  }
  return fullType;
};

export const changeTypeNumberForReportText = (
  type: string
): AddReportListType => {
  switch (type) {
    case "HEALTH":
      return "health";
      break;
    case "CVV":
      return "cvv";
      break;
    case "THUMBNAIL":
      return "thumbnail";
      break;
    case "INVENTORY":
      return "inventory";
      break;
    case "INSTALLATION":
      return "installation";
      break;
    case "TIMELAPSE":
      return "timelapse";
      break;
    default:
      return "health";
  }
};

/**
 *
 * @param current number 현재 용량
 * @param total number 총 용량
 * @description 비율 계산
 */
export const calcStoragePercent = (
  current: number,
  total: number,
  max?: number
) => {
  if (total === 0 || total === undefined) {
    return 0;
  }
  const percent = (current / total) * 100;
  return max !== undefined ? (percent >= 100 ? 100 : percent) : percent;
};

/**
 *
 * @param current number 현재 용량
 * @param total number 총 용량
 * @returns 90% 이상이면 true, 아니면 false
 */
export const isFullStorage = (current: number, total: number) => {
  if (calcStoragePercent(current, total) >= 90) return true;
  else return false;
};

// export const commonFormat = (date: string | Date) => {
//   return `${moment(timestamp).format("MM/DD/YYYY")}  ${moment(timestamp)
//     .tz(timezone ? timezone : "UTC")
//     .format("h:mm:ss A z")}`;
// };

/**
 * @param bytes number 현재 용량
 * @param decimals 자리수
 * @returns ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
 */

// byte를 적절한 단위로 변환 후 값과 단위 따로 분리해서 결과값 도출
export function formatBytesAndUnits(bytes: number): {
  value: number;
  unit: string;
} {
  if (bytes === 0) return { value: 0, unit: "Bytes" };

  const units = ["Bytes", "KB", "MB", "GB", "TB", "PB"];
  const index = Math.floor(Math.log(bytes) / Math.log(1024));
  const value = parseFloat((bytes / Math.pow(1024, index)).toFixed(2));

  return { value, unit: units[index] };
}

// TB를 1024 Byte로 변환하는 함수
export const convertTBtoBytes = (tb: number): number => {
  const binaryFactor = Math.pow(2, 40); // 2^40 (1TB = 1,099,511,627,776 bytes)

  return tb * binaryFactor;
};

// byte 를 tb 로 변환하는 함수
export const convertBytesToTB = (
  bytes: number,
  binary: boolean = false
): number => {
  const decimalFactor = 1e12; // 10^12 (1TB = 1,000,000,000,000 bytes)
  const binaryFactor = Math.pow(2, 40); // 2^40 (1TB = 1,099,511,627,776 bytes)

  return bytes / (binary ? binaryFactor : decimalFactor);
};

export const formatBytesFromMB = (bytes: number, decimals = 1) => {
  if (bytes === 0) return "";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export const formatBytes = (bytes: number, decimals = 1) => {
  if (bytes === 0) return "";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  let i = Math.min(
    Math.floor(Math.log(bytes) / Math.log(1024)),
    sizes.length - 1
  );
  if (i === 0) return `${bytes} ${sizes[i]}`;
  return `${(bytes / 1024 ** i).toFixed(dm)} ${sizes[i]}`;
};

export const formatBytesMemory = (bytes: number, decimals?: number) => {
  if (bytes === 0) return "";
  if (decimals === undefined) {
    decimals = 1;
  }
  const k = 1000;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  let i = Math.min(
    Math.floor(Math.log(bytes) / Math.log(1000)),
    sizes.length - 1
  );
  if (i === 0) return `${bytes} ${sizes[i]}`;
  return `${(bytes / 1000 ** i).toFixed(dm)} ${sizes[i]}`;
};

export const getGBFromByteString = (byte: string) => {
  if (byte === undefined || !isNumber(byte)) {
    return "";
  }
  return formatBytesMemory(Number(byte), 0);
};
/**
 *
 * @param timestamp string ex)2022-10-19T09:00:00.292000Z
 * @return 19/10/2022 2:00:00 AM PDT
 *
 */
export const changeTimeStampFormat = (
  timestamp: string | Date,
  timezone?: string
) => {
  return `${moment(timestamp).format("MM/DD/YYYY")}
  \u00A0
  ${moment(timestamp)
    .tz(timezone ? timezone : "UTC")
    .format("h:mm:ss A z")}`;
};

export const changeTimeStampFormatDaySelect = (
  timestamp: string | Date,
  timezone?: string
) => {
  return moment(timestamp).format("MM/DD/YYYY");
};

/**
 *
 * @param date moment / string
 * @returns 03/11/2022 Thu
 */

export const changeDateToFormat = (date: Moment | string) => {
  return moment(date).format("MM/DD/YYYY");
};

export const changeDateToFormatMonth = (date: Moment | string) => {
  return moment(date).format("MM/YYYY");
};

export const DateFromString = (date: string) => {
  if (date !== undefined && date.length === 10) {
    const sYear = date.substring(0, 4);
    const sMonth = date.substring(5, 7);
    const sDate = date.substring(8, 10);
    return new Date(Number(sYear), Number(sMonth) - 1, Number(sDate));
  } else {
    return new Date();
  }
};

/**
 *
 * @returns 24시간을 30분 단위로 나눠서 00:00 로 변환
 */
export const createTime24for30min = () => {
  let temp = [];
  let hour = "";
  let time = "";
  let period = "";

  for (let i = 0; i < 24; i++) {
    for (let j = 0; j <= 30; j += 30) {
      // Military time 그대로 사용
      const militaryHour = i < 10 ? `0${i}` : `${i}`;
      time = j === 0 ? `0${j}` : `${j}`;

      // AM/PM 구분 설정
      if (i === 0) {
        hour = "12"; // 12 AM
        period = "AM";
      } else if (i < 12) {
        hour = i < 10 ? `0${i}` : `${i}`;
        period = "AM";
      } else if (i === 12) {
        hour = "12"; // 12 PM
        period = "PM";
      } else {
        hour = i - 12 < 10 ? `0${i - 12}` : `${i - 12}`;
        period = "PM";
      }

      temp.push({
        label: `${hour}:${time} ${period}`, // AM/PM 형식으로 표시
        value: `${militaryHour}:${time}`, // military 시간 유지
      });
    }
  }
  return temp;
};

/**
 *
 * @param date1 string 이나 moment 형식
 * @param date2 string 이나 moment 형식
 * @returns  절대값으로 받아옴
 */
export const substractTime = (
  date1: string | moment.Moment,
  date2: string | moment.Moment
) => {
  let returnSubstractTimeString = "";
  let formatDate1 = moment(date1);
  let formatDate2 = moment(date2);

  // console.log(formatDate1 + "/" + formatDate2);
  var diffTime = {
    month: moment.duration(formatDate2.diff(formatDate1)).months(),
    day: moment.duration(formatDate2.diff(formatDate1)).days(),
    hour: moment.duration(formatDate2.diff(formatDate1)).hours(),
    minute: moment.duration(formatDate2.diff(formatDate1)).minutes(),
  };
  // console.log(diffTime);
  if (diffTime.month !== 0) {
    return returnSubstractTimeString.concat(
      String(Math.abs(diffTime.month)),
      diffTime.month === 1 ? " Month" : " Months"
    );
  }
  if (diffTime.day !== 0) {
    return returnSubstractTimeString.concat(
      String(Math.abs(diffTime.day)),
      diffTime.day === 1 ? " Day" : " Days"
    );
  }
  if (diffTime.hour !== 0) {
    return returnSubstractTimeString.concat(
      String(Math.abs(diffTime.hour)),
      diffTime.hour === 1 ? " Hour" : " Hours"
    );
  }
  if (diffTime.minute !== 0) {
    return returnSubstractTimeString.concat(
      String(Math.abs(diffTime.minute)),
      diffTime.minute === 1 ? " Minute" : " Minutes"
    );
  }
  return returnSubstractTimeString;
};

/**
 *
 * @param string string
 * @returns ex. #ff1234
 */
export const stringToColor = (string: string) => {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
};

/**
 *
 * @param name ex. username
 * @returns user 프로필 축약어 ex. Arm Strong -> AS
 */
export const stringAvatar = (name: string) => {
  if (name !== undefined && !isEmpty(name)) {
    const removeWhite = name.replace(/^\s+|\s+$/gm, "");
    const nameCount = removeWhite.split(" ").length;

    if (nameCount === 1) {
      const splittedName = removeWhite.split(" ")[0];
      if (splittedName.length >= 2) {
        return `${removeWhite.split(" ")[0][0].toUpperCase()}${removeWhite
          .split(" ")[0][1]
          .toUpperCase()}`;
      } else {
        return `${removeWhite.split(" ")[0][0].toUpperCase()}`;
      }
    } else {
      return `${removeWhite.split(" ")[0][0].toUpperCase()}${removeWhite
        .split(" ")
        [nameCount - 1][0].toUpperCase()}`;
    }
  } else {
    return "NA";
  }
};

export const calRowCount = () => {
  var maxWidth = window.innerWidth;
  // console.log("availWidth=" + maxWidth);
  let count = 0;
  if (maxWidth >= 3800) {
    count = 25;
  } else if (maxWidth > 2560) {
    count = 18;
  } else if (maxWidth > 1920) {
    count = 15;
  } else {
    count = 10;
  }
  return count;
};

export const getClientDevice = () => {
  var os,
    ua = navigator.userAgent;
  if (OsDetect.isDesktop) {
    if (OsDetect.isWindows) {
      return "Window PC";
    } else if (OsDetect.isMacOs) {
      return "MAC PC";
    } else if (ua.match(/Linux/)) {
      return "Linux PC";
    } else {
      return "PC";
    }
  } else if (OsDetect.isTablet) {
    if (OsDetect.isIOS) {
      return "IPad";
    } else if (OsDetect.isWindows) {
      return "Window Tablet";
    } else if (OsDetect.isAndroid) {
      return "Android Tablet";
    } else {
      return "Tablet";
    }
  } else if (OsDetect.isMobile) {
    if (OsDetect.isAndroid) {
      return "Android Phone";
    } else if (OsDetect.isIOS) {
      return "IPhone";
    } else if (OsDetect.isMIUI) {
      return "MIUI Phone";
    } else {
      return "Phone";
    }
  } else if (OsDetect.isSmartTV) {
    return "Smart TV";
  } else {
    return "ETC";
  }
};

export const changeStatusString = (status: string) => {
  switch (status) {
    case "CLOSED":
      return "Closed";
    case "ASSIGNED":
      return "Assigned";
    case "ACTIVE":
      return "Active";
    default:
      return status;
  }
};

export const convertType = (type: string, brand?: string): RecorderType => {
  if (type === undefined) {
    return "UNKNOWN";
  }

  switch (type.toUpperCase()) {
    case "NVR":
      return brand?.toUpperCase() === "TVT" ? "COVA" : "VMAX-IP";
    case "DVR":
      return "VMAX-A1";
    case "SPECTRUM":
      return "SPECTRUM";
    default:
      return "UNKNOWN";
  }
};

export const changeLayoutToRes = (layouts: Layout[]) => {
  return layouts.map((layout) => {
    return {
      ...layout,
      h: layout.h + 1,
    };
  });
};

//Recorder list 에서 하위 system의 alarm 상태를 check하는 function
export const checkStatus = (totalCount: number, onlineCount: number) => {
  if (totalCount === 1) {
    if (totalCount === onlineCount) {
      return "online";
    } else {
      return "offline";
    }
  } else {
    if (onlineCount === 0) {
      return "offline";
    } else if (totalCount === onlineCount) {
      return "online";
    } else {
      return "warning";
    }
  }
};

export const getDuplicateCount = (arr: any[]) => {
  let result: any = {};
  for (const el of arr) {
    result[el] = (result[el] || 0) + 1;
  }
  return result;
};

export const filterNonNull = (obj: any) => {
  return Object.fromEntries(Object.entries(obj).filter(([k, v]) => v));
};

/**
 * 배열 사람명수 외 표시
 * ex) steven armtsrong + 2
 */
export const showCount = (arr: string[]) => {
  if (arr.length === 0) {
    return "Tampered";
  } else if (arr.length > 2) {
    return `${arr.filter((a) => a)[0].concat(", ", arr[1])} + ${
      arr.length - 2
    }`;
  } else return arr.join(", ");
};

/**
 * html 태그 없애기
 */
export const deleteHtmlTag = (string: string) => {
  const reg = /<[^>]*>?/g;
  return string !== undefined ? string.replace(reg, "") : "";
};

export const openInNewTab = (url: string) => {
  window.open(url, "_blank", "noreferrer");
};

/**
 * tree array -> flatten array
 * @param destArray
 * @param nodeList
 */
export const flatten = (destArray: any[], nodeList: any[]) => {
  nodeList.forEach((node) => {
    destArray.push(node);
    flatten(destArray, node.subRows || []);
  });
};

export const flattenArray = (nodeList: any[]) => {
  let temp: any[] = [];
  flatten(temp, nodeList);
  return temp;
};

const renameKeys = (obj: any, newKeys: any) => {
  const keyValues = Object.keys(obj).map((key) => {
    const newKey = newKeys[key] || key;
    return { [newKey]: obj[key] };
  });
  return Object.assign({}, ...keyValues);
};
export const renameObjectKeys = (obj: any, newKeys: any) => {
  const renamedObj = renameKeys(obj, newKeys);

  if (obj.subRows) {
    renamedObj.children = obj.subRows.map((subRow: any) =>
      renameObjectKeys(subRow, newKeys)
    );
  }
  return renamedObj;
};

export const renameArrayKeys = (array: any[], newKeys: any) => {
  return array.map((arr) => renameObjectKeys(arr, newKeys));
};

export const isEditAble = (
  accountPrivilege: ProfileAccountInfo,
  userInfo: UserInfo,
  createBy: string | undefined,
  email: string
) => {
  let isEditable: boolean = false;
  //BUG [RND-455] 매니져권힌이 있는 경우 편집 가능
  if (
    accountPrivilege.isAdmin ||
    accountPrivilege.isManager ||
    accountPrivilege.checkedUserAdmin
  ) {
    isEditable = true;
  }
  if (createBy === "System") {
    if (
      accountPrivilege.isAdmin ||
      accountPrivilege.isManager ||
      accountPrivilege.checkedUserAdmin
    ) {
      isEditable = true;
    }
  }
  if (email !== undefined && email === userInfo.email) {
    isEditable = true;
  }
  return isEditable;
};

export const extractFileName = (path: string) => {
  let fileName = path.split("/").pop();
  if (fileName !== undefined) {
    fileName = fileName.split(".")[0];
  }
  return fileName;
};

export const getStartPageUrl = (page: number) => {
  let startUrl = "/dashboard/map";
  switch (page) {
    case 1:
      startUrl = "/dashboard/map";
      break;
    case 2:
      startUrl = "/dashboard/chart";
      break;
    case 3:
      startUrl = "/dashboard/admin";
      break;
    case 4:
      startUrl = "/account";
      break;
    case 5:
      startUrl = "/recorders/list";
      break;
    default:
      startUrl = "/dashboard/map";
      break;
  }
  return startUrl;
};

export const getDomainUrl = (url: string) => {
  if (url === undefined || isEmpty(url)) {
    return "";
  } else if (url.includes("onvif/device_service")) {
    const urlObject = new URL(url);
    return urlObject.origin;
  } else {
    return url;
  }
};

export const isNumber = (s: any) => {
  s += ""; // 문자열로 변환
  s = s.replace(/^\s*|\s*$/g, ""); // 좌우 공백 제거
  if (s === "" || isNaN(s)) return false;
  return true;
};

export const checkCVVError = (item: CvvItem) => {
  return item.blur || item.dust || item.glare || item.spider || item.tampered;
};

export const checkCVVErrorBlocking = (item: CvvItem) => {
  return item.blur || item.dust || item.glare || item.spider;
};

export const handleCopyClipBoard = (text: string) => {
  navigator.clipboard
    .writeText(text)
    .then(() => {
      notify("success", "Success to copy Organization ID");
    })
    .catch(() => {
      notify("error", "Failed to copy Organization ID");
    });
};

export const getIDfromYoutubeUrl = (url: string) => {
  const regExp =
    /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
  const match = url.match(regExp);

  if (match && match[2].length === 11) {
    return match[2];
  }

  return "";
};

//YouTube URL에서 재생목록이나 추가 파라미터를 제외하고 기본 URL만 추출
export const extractYouTubeBaseUrl = (url: string): string => {
  const urlObj = new URL(url);
  const videoId = urlObj.searchParams.get("v");

  // 'v' 파라미터를 제외한 나머지 삭제
  urlObj.searchParams.forEach((_, key) => {
    if (key !== "v") {
      urlObj.searchParams.delete(key);
    }
  });

  // console.log(url, urlObj, videoId, urlObj.toString());
  // v 파라미터가 있는 경우 기본 URL 반환
  if (videoId) {
    return `https://www.youtube.com/watch?v=${videoId}`;
  }

  // v 파라미터가 없는 경우 수정된 URL 반환
  return urlObj.toString();
};

export const matchHashTag = (selectTags: string[], compareTag: string) => {
  if (selectTags === undefined || compareTag === undefined) {
    return false;
  } else {
    const match = selectTags.find(
      (selectTag) => selectTag.toLowerCase() === compareTag.toLowerCase()
    );
    if (match) {
      return true;
    } else {
      return false;
    }
  }
};

export const downloadFromUrl = async (url: string, fileName?: string) => {
  const response = await fetch(url);
  const file = await response.blob();
  const downloadUrl = window.URL.createObjectURL(file);

  const anchorElement = document.createElement("a");
  document.body.appendChild(anchorElement);
  if (fileName !== undefined) {
    anchorElement.download = fileName;
  }
  anchorElement.href = downloadUrl;
  anchorElement.click();

  document.body.removeChild(anchorElement);
};

// url 을 file 형식으로 변환
export const convertURLtoFile = async (url: string) => {
  const response = await fetch(url);
  const data = await response.blob();
  const ext = url.split(".").pop(); // url 구조에 맞게 수정할 것
  const filename = url.split("/").pop(); // url 구조에 맞게 수정할 것
  const metadata = { type: `image/${ext}` };
  return new File([data], filename!, metadata);
};

export const openNewWindow = (targetUrl: string) => {
  var link = document.getElementById("link");
  var linkElement = document.createElement("a");
  linkElement.id = "link";
  linkElement.target = "_blank";
  window.document.body.appendChild(linkElement);
  linkElement.setAttribute("href", targetUrl);
  window.document.body.removeChild(linkElement);
  linkElement.click();
};

/**
 * 대소문자 구별없이 검색 true / false 로 변환
 * @param string
 * @param searchInput
 * @returns string 이 searchInput 에 포함될경우 : true / 불포함일경우 :false
 */
export const matchStringSearchKeyword = (
  string: string,
  searchInput: string
) => {
  if (typeof string !== "string") {
    console.error("Invalid string input:", string);
    return false;
  }

  const match = string.match(new RegExp(searchInput, "i"));
  // console.log(match);
  return match !== null;
};

var blank_pattern = /^\s+|\s+$/g;

/**
 *
 * @param str keyword
 * @returns boolean
 * @description 공백만 인지 아닌지 확인
 */
export const checkBlankSpace = (str: string) => {
  return str.replace(blank_pattern, "") === "";
};

/**
 *
 * @param str keyword
 * @returns boolean
 * @description 아무것도 없을때는 검색 가능하지만 공백만 있을때는 검색 불가능
 */
export const checkKeywordSpace = (str: string) => {
  // console.log(str !== "", checkBlankSpace(str));
  return str !== "" && checkBlankSpace(str);
};

export const getChartColorSchema = () => {
  const category10 =
    "1f77b4ff7f0e2ca02cd627289467bd8c564be377c27f7f7fbcbd2217becf";
  var n = (category10.length / 6) | 0,
    colors = new Array(n),
    i = 0;
  while (i < n) colors[i] = "#" + category10.slice(i * 6, ++i * 6);
  return colors;
};

export const maskInternationalPhoneNumber = (phoneNumber: string): string => {
  // 정규식을 사용하여 가운데 4~6번째 자리를 *로 대체
  return phoneNumber.replace(/(\+\d{1,3})(\d{3})(\d{3})(\d{4})/, "$1$2***$4");
};

export const isVideoFile = (fileName: string): boolean => {
  const acceptableVideos = ['.webm', '.mp4'];
  return acceptableVideos.some((ext) => {
    return fileName.endsWith(ext);
  });
}

export const isImageFile = (fileName: string): boolean => {
  const acceptableVideos = ['.png', '.jpeg', 'jpg', 'bmp', 'gif', 'webp'];
  return acceptableVideos.some((ext) => {
    return fileName.endsWith(ext);
  });
}