import { COLORS } from "styles/colors";
import styled from "styled-components";
import { FLEX } from "styles/flex";
import { determineLableColor } from "../../GoogleMapContainer";

export interface Props {
  alertCount: number;
  criticalCount: number;
  totalRecorder: number;
}

export default function ClusterMarker(props: Props): JSX.Element {
  return (
    <Wrapper
      status={{
        alertCount: props.alertCount,
        criticalCount: props.criticalCount,
      }}
    >
      {props.totalRecorder > 999 ? "999+" : props.totalRecorder} SYSTEMS
    </Wrapper>
  );
}

const Wrapper = styled.div<{
  status: { alertCount: number; criticalCount: number };
}>`
  ${FLEX.FlexCenterCenter};
  padding: 6px 10px;
  border-radius: 8px;
  position: relative;
  color: ${COLORS.WHITE};
  font-weight: 500;
  z-index: 2;
  background-color: ${(props) =>
    determineLableColor(props.status.alertCount, props.status.criticalCount)};

  :after {
    content: "";
    position: absolute;
    border-style: solid;
    border-width: 20px 8px 0;
    border-color: ${(props) =>
        determineLableColor(
          props.status.alertCount,
          props.status.criticalCount
        )}
      transparent transparent;
    width: 0;
    bottom: -8px;
    z-index: -1;
  }
`;
