import { getWidgetAccountList } from "api/widgetAPI";
import { useQuery } from "react-query";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { useAppSelector } from "redux/hooks";
import { AccountList } from "api/interfaces/widgetInterface.interface";
import { useEffect, useState } from "react";
import { Option } from "utils/options";
import { useIntl } from "react-intl";
import SelectsCombo from "components/atoms/select/SelectCombo";
import styled from "styled-components";

import * as mediaQuery from "components/MediaQuery";
import { LEVEL_TYPE } from "api/interfaces/commonInterface.interface";
import Select, {
  components,
  GroupBase,
  MultiValue,
  OnChangeValue,
  MultiValueProps,
  OptionProps,
  StylesConfig,
  ValueContainerProps,
  SingleValue,
} from "react-select";
import { COLORS, LIGHT_THEME_COLORS } from "styles/colors";
import { calcRem } from "styles/theme";
import { FLEX } from "styles/flex";

interface Props {
  value: string[];
  defaultValue?: any;
  isDisabled?: boolean;
  onChange?: (value: string[]) => void;
  placeholder?: string;
  id?: string;
}
const CustomOption = (props: OptionProps<any, boolean, GroupBase<Option>>) => {
  return (
    <components.Option {...props}>
      <div style={{ display: "flex", alignItems: "flex-start", width: "100%" }}>
        <input
          type="checkbox"
          checked={props.isSelected}
          readOnly={true}
          onChange={() => null}
          style={{ marginRight: 5, flexShrink: 0 }}
        />
        <span
          style={{
            flexGrow: 1,
            whiteSpace: "normal",
            wordWrap: "break-word",
            fontSize: 12,
          }}
        >
          {props.label}
        </span>
      </div>
    </components.Option>
  );
};

const CustomValueContainer = (
  props: ValueContainerProps<any, boolean, GroupBase<Option>>
) => {
  let [values, input] = props.children as any;

  if (Array.isArray(values)) {
    values =
      values.length === 1 ? (
        <div className="value-container-text-inner">
          {values[0].props.data.label}
        </div>
      ) : (
        <>
          <div className="value-container-text-inner">
            {values[0].props.data.label}
          </div>{" "}
          <span className="plus">+{values.length - 1}</span>
        </>
      );
  }

  return (
    <components.ValueContainer
      {...props}
      className="account-select-value-container"
    >
      <div className="value-container-text">{values}</div> {input}
    </components.ValueContainer>
  );
};
export const customDashboardSelectStyles: StylesConfig<Option> = {
  control: (styles, state) => {
    let isLightTheme =
      state.selectProps.className?.includes("select-light-mode");
    const backgroundColor = state.isDisabled
      ? isLightTheme
        ? LIGHT_THEME_COLORS.LIGHTGRAY
        : COLORS.BUTTONS1
      : COLORS.WHITE;
    const borderColor =
      state.isDisabled && isLightTheme ? LIGHT_THEME_COLORS.BORDER : "#cacaca";
    const maxHeight = "33px";
    const minHeight = "33px";

    return { ...styles, backgroundColor, borderColor, maxHeight, minHeight };
  },
  container: (styles, state) => {
    let isLightTheme =
      state.selectProps.className?.includes("select-light-mode");

    return {
      ...styles,
      width: "100%",
      height: "100%",
      color: state.isDisabled
        ? isLightTheme
          ? `${COLORS.BLACK}60`
          : COLORS.WHITE
        : COLORS.BLACK,
      fontSize: calcRem(13),
      fontWeight: "normal",
    };
  },
  valueContainer: (styles, state) => {
    let isLightTheme =
      state.selectProps.className?.includes("select-light-mode");

    return {
      ...styles,
      color: state.isDisabled
        ? isLightTheme
          ? `${COLORS.BLACK}60`
          : `${COLORS.WHITE}60`
        : COLORS.BLACK,
      fontSize: calcRem(13),
      fontWeight: "normal",
    };
  },
  menu: (provided) => ({ ...provided, zIndex: 78 }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 78,
    fontSize: 12,
  }),
  placeholder: (styles, state) => {
    return {
      ...styles,
      color: COLORS.BLACK,
      fontWeight: "normal",
    };
  },
  indicatorSeparator: () => ({}),
  indicatorsContainer: (styles) => ({
    ...styles,
    padding: 0,
    height: "33px",
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    padding: 0,
    color: COLORS.BUTTONS1,
  }),
  // option: (provided: any) => ({
  //   ...provided,
  //   display: "flex",
  //   alignItems: "center",
  //   width: "100%", // 고정된 너비
  //   whiteSpace: "normal", // 텍스트가 줄바꿈될 수 있도록 설정
  //   wordWrap: "break-word", // 텍스트가 고정 너비를 넘어가면 줄바꿈
  // }),
};

export default function AccountSelects(props: Props): JSX.Element {
  const theme = useAppSelector((state) => state.theme.theme);
  const intl = useIntl();
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );

  const [options, setOptions] = useState<Option[]>([]);

  const onChange = (newValue: MultiValue<Option> | SingleValue<Option>) => {
    // 다중 선택일 경우 처리
    let tempValue: string[] =
      newValue && Array.isArray(newValue)
        ? newValue.map((v: Option) => v.value)
        : [];

    // 부모 컴포넌트로 값 전달
    props.onChange && props.onChange(tempValue);
  };

  const { error, refetch } = useQuery(
    ["getWidgetAccountList"],
    () =>
      getWidgetAccountList({
        accountId: selectedAccount.accountId,
      }),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      onSuccess: (res: AccountList) => {
        if (res.error !== 0 && res.result === undefined) {
          setOptions([]);
        } else {
          setOptions(
            res.result.map((res) => {
              return {
                label: res.name,
                value: res.accountId,
              };
            })
          );
        }
      },
      onError: (err: any) => {
        setOptions([]);
        // notify(
        //   "error",
        //   intl.formatMessage({
        //     id: "label.alert.notify.list.error",
        //     defaultMessage: "Alert search error.",
        //   })
        // );
      },
    }
  );

  useEffect(() => {
    if (selectedAccount.accountLevel === LEVEL_TYPE.EU && props.onChange) {
      props.onChange([]);
    }
  }, [selectedAccount.accountLevel]);

  return selectedAccount.accountLevel === LEVEL_TYPE.EU ? (
    <></>
  ) : (
    <SelectWrapper className="account-select-wrapper">
      <Select
        isMulti
        options={options}
        value={options.filter((opt) => props.value.includes(opt.value))}
        placeholder={
          props.value.length === 0 || props.value.length === options.length
            ? "All Organizations"
            : "Select"
        }
        styles={customDashboardSelectStyles}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        isClearable={false}
        backspaceRemovesValue={false}
        onChange={onChange}
        components={{
          Option: CustomOption,
          ValueContainer: CustomValueContainer,
        }}
        className={`select-container select-${theme}-mode`}
        isSearchable={false}
        menuPortalTarget={document.querySelector("body")}
      />
    </SelectWrapper>
  );
}

const SelectWrapper = styled.div`
  width: 150px;
  /* max-width: 150px; */
  .account-select-value-container {
    input {
      position: absolute;
      opacity: 0;
    }
    .value-container-text {
      ${FLEX.FlexStartCenter};
      gap: 2px;
      width: 100%;
    }
    .value-container-text-inner {
      white-space: nowrap; /* 텍스트가 한 줄로 유지되도록 설정 */
      overflow: hidden; /* 넘치는 텍스트는 숨김 처리 */
      text-overflow: ellipsis; /* 말줄임표(...) 표시 */
      display: inline-block; /* 말줄임표 적용을 위해 block 요소로 변경 */
      /* flex: 1 1 auto; */
      /* width: 100%; */
    }

    .plus {
      flex: 1 1 auto;
    }
  }

  ${mediaQuery.isMobile} {
    width: 100%;
  }
`;
