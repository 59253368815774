import styled from "styled-components";
import { COLORS, LIGHT_THEME_COLORS } from "styles/colors";
import { FLEX } from "styles/flex";
import * as mediaQuery from "components/MediaQuery";
import { calcRem } from "styles/theme";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;

  ${FLEX.FlexStartStart};
  flex-direction: column;

  .no-data {
    padding: 30px 0;

    > img {
      width: 20%;
      /* max-width: 70px; */
    }
  }
`;

export const SpectrumInner = styled.div<{ isMap: boolean }>`
  width: 100%;
  height: 100%;
  ${FLEX.FlexStartStart};
  gap: 10px;

  .inner-box {
    ${FLEX.FlexStartStart};
    flex-direction: column;

    height: 100%;
    max-height: 100%;
  }

  .left {
    width: 100%;
    gap: 20px;
    flex: 4;
  }

  ${({ isMap, theme }) =>
    !isMap &&
    `
    margin-top: 10px;

    .right {
      flex: 1;
      /* flex-basis: 200px; */
      width: 200px;
      max-width: 200px;
      background-color: ${
        theme.value === "light" ? theme.COLORS.PRIMARY2 : "#1c2639"
      };
      border-radius: 6px;
    }
  `}

  ${({ isMap }) =>
    isMap &&
    `
    flex-direction: column;

    .guage-wrapper {
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      max-width: 100%;

      > div {
        flex: 1;
      }
    }

  `}

  ${mediaQuery.isMobile} {
    flex-direction: column;

    .right {
      width: 100%;
      max-width: 100%;
    }
  }
`;

export const VmaxInner = styled(SpectrumInner)`
  width: 100%;
  height: 100%;
  ${FLEX.FlexCenterCenter};

  .graph {
    width: 100%;
    height: 100%;
    max-width: 1000px;
    ${FLEX.FlexBetweenCenter};
    flex-direction: column;
  }
`;

export const TopContents = styled.ul`
  ${FLEX.FlexStartStart};
  flex-direction: column;
  width: 100%;
  > li {
    width: 100%;
    padding: 8px 0;
    border-bottom: 1px solid ${({ theme }) => theme.COLORS.BORDER};
    font-size: ${calcRem(14)};
    color: ${COLORS.BUTTONS1};
    ${FLEX.FlexStartCenter};
    gap: 10px;

    .title {
      color: ${({ theme }) => theme.COLORS.WHITE};
      flex: 1;
    }

    .content {
      flex: 3;
      color: ${COLORS.BUTTONS1};
    }
  }
`;

export const SystemTable = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.COLORS.WHITE};
  .title {
    font-weight: 500;
  }
`;

export const GuageBox = styled.div`
  width: 100%;
  max-width: 100%;
  position: relative;
  text-align: center;
  padding-bottom: 20px;

  span {
    color: ${({ theme }) => theme.COLORS.WHITE};
    font-size: ${calcRem(14)};
    font-weight: 500;
    position: absolute;
    left: 50%;
    bottom: 15px;
    transform: translateX(-50%);
  }
`;

export const VmaxBox = styled.div`
  width: 100%;
  height: 100%;
  padding: 10px;
  flex: 1;
  margin-bottom: 20px;
  text-align: center;

  .connect-box {
    width: 100%;
    ${FLEX.FlexBetweenCenter};
  }
`;

export const Item = styled.div<{
  isMap: boolean;
  status: boolean;
}>`
  width: 110px;
  height: 110px;
  border-radius: 50%;
  // #828B9B
  border: 1px solid;
  font-size: ${calcRem(13)};
  ${FLEX.FlexCenterCenter};
  flex-direction: column;
  gap: 8px;
  position: relative;
  background-color: ${({ theme }) =>
    theme.value === "light"
      ? `${LIGHT_THEME_COLORS.LIGHTGRAY}`
      : `rgb(14, 23, 34)`};
  z-index: 5;

  ${({ status, theme }) =>
    status
      ? `
    border-color: ${theme.COLORS.SELECTED};
    color: ${theme.COLORS.SELECTED};
  `
      : `
    border-color: ${COLORS.LIGHTGRAY};
    color: ${COLORS.LIGHTGRAY};
  `}

  ${({ isMap }) =>
    isMap &&
    `
      width: 100px;
      height: 100px;
      font-size: ${calcRem(12)};

      > svg {
        width: 30%;
      }
  `}

  ${mediaQuery.isMobile} {
    width: 80px;
    height: 80px;
    gap: 6px;
    font-size: ${calcRem(12)};

    > svg {
      width: 40%;
    }
  }
`;

export const GraphWrapper = styled.div<{
  isMap: boolean;
  ddns: boolean;
  gateway: boolean;
  recorder: boolean;
}>`
  width: 100%;
  ${FLEX.FlexStartCenter};
  flex-direction: column;

  .line {
    position: relative;

    ::before {
      content: "";
      position: absolute;
      border: 2px solid;
      z-index: 1;
    }
  }

  .network {
    width: calc(50% - 165px);

    ${({ isMap }) =>
      isMap &&
      `
      width: calc(50% - 150px);
      `}

    ${mediaQuery.isMobile} {
      width: calc(50% - 120px);
    }

    ::before {
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      width: 100%;
      border-color: ${({ theme }) => theme.COLORS.SELECTED};
    }
  }
  .ddns {
    ::before {
      ${({ ddns, theme }) =>
        ddns
          ? ` 
      border-color: ${theme.COLORS.SELECTED};`
          : `
      border-color: ${COLORS.LIGHTGRAY};
      `}
    }
  }

  .gateway {
    width: 100%;
    min-height: 45px;

    ::before {
      height: 100%;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .client {
    ${FLEX.FlexBetweenCenter};

    > div {
      color: ${({ theme }) => theme.COLORS.SELECTED};
      ${FLEX.FlexStartCenter};
      gap: 1px;
      flex-direction: column;
      font-size: ${calcRem(12)};
      width: 110px;

      ${mediaQuery.isMobile} {
        width: 80px;
      }
    }

    ::before {
      ${({ gateway, theme }) =>
        gateway
          ? ` 
            border-color: ${
              theme.value === "light" ? COLORS.BUTTONS2 : COLORS.GREEN
            };`
          : `
            border-color: ${COLORS.LIGHTGRAY};
          `}
    }
  }

  .port {
    position: relative;

    > div {
      height: 100%;
      ${FLEX.FlexStartCenter};
      color: ${COLORS.WHITE};
      position: absolute;
      font-size: ${calcRem(12)};
      top: 50%;
      left: 52%;
      transform: translateY(-50%);
    }

    ::before {
      ${({ recorder, theme }) =>
        recorder
          ? ` 
            border-color: ${
              theme.value === "light" ? COLORS.BUTTONS2 : COLORS.GREEN
            }`
          : `
            border-color: ${COLORS.LIGHTGRAY};
          `}
    }
  }
`;

export const PortBox = styled.div`
  border: 1px solid
    ${({ theme }) => (theme.value === "light" ? COLORS.BUTTONS2 : COLORS.GREEN)};
  color: ${({ theme }) =>
    theme.value === "light" ? COLORS.BUTTONS2 : COLORS.GREEN};
  /* width: 35%; */
  text-align: center;
  font-size: ${calcRem(14)};
  padding: 8px;
  min-width: 250px;
  max-width: 100%;
  /* width: 100%; */
  margin-top: 18px;
`;

export const PortList = styled.ul`
  ${FLEX.FlexCenterCenter};
  min-width: 100%;
  max-width: 100%;
  /* width: 100%; */
  gap: 8px;
  margin-top: 10px;
  flex-flow: row wrap;
`;

export const Port = styled.li<{ enable: boolean; online: boolean ; watt:number }>`
  ${FLEX.FlexStartCenter};
  flex-direction: column;
  font-size: ${calcRem(11)};
  gap: 2px;
  ${({ watt, online, theme }) =>
    watt !== 0 &&
    online &&
    `
    color: ${theme.value === "light" ? COLORS.BUTTONS2 : COLORS.GREEN};
  `};
  ${({ watt, online }) =>
    watt !== 0 &&
    !online &&
    `
    color: ${COLORS.WARNING};
  `};
  ${({ watt, online }) =>
    watt === 0  &&
    `
    color: ${COLORS.LIGHTGRAY};
  `};

`;

export const GateWay = styled.div<{ status: boolean }>`
  padding: 10px 18px;
  background-color: ${({ theme }) =>
    theme.value === "light" ? theme.COLORS.PRIMARY3 : COLORS.PRIMARY1};
  color: ${({ theme }) => theme.COLORS.WHITE};
  font-size: ${calcRem(14)};
  ${FLEX.FlexCenterCenter};
  gap: 12px;
  width: max-content;
  border-radius: 4px;
  border: 1px solid;

  svg {
    width: 30px;
  }

  .ip-address {
    ${FLEX.FlexAroundStart};
    flex-direction: column;
    gap: 2px;
  }

  ${({ status, theme }) =>
    status
      ? `
    border-color: ${theme.value === "light" ? COLORS.BUTTONS2 : COLORS.GREEN}
  `
      : `
  border-color: ${COLORS.LIGHTGRAY};
  
  `}
`;

export const PortWrapper = styled.div<{ status: boolean }>`
  width: 100%;
  text-align: center;
  margin-top: 10px;

  .camera-info {
    ${FLEX.FlexCenterCenter};
    flex-direction: column;
    gap: 6px;

    * {
      font-weight: 500;
      color: ${COLORS.WHITE};
    }
    span {
      :nth-child(2) {
        font-size: ${calcRem(13)};
      }
    }
  }

  .ip-address {
    ${({ status, theme }) =>
      status
        ? `

    color: ${theme.value === "light" ? COLORS.BUTTONS2 : COLORS.GREEN}
  `
        : `
  color: ${COLORS.LIGHTGRAY};
  
  `}
  }
`;

export const CameraStatusList = styled.ul<{ isMap: boolean }>`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.COLORS.BORDER};

  ${({ theme }) =>
    theme.value === "light"
      ? `
      background-color: ${COLORS.WHITE}90;

    `
      : `
      background-color: #1c2639;
  `}
  border-radius: 4px;
  /* ${FLEX.FlexStartStart};
  flex-flow: row wrap;
  gap: 16px; */
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  row-gap: 16px;
  padding: 18px;

  ${({ isMap }) =>
    isMap &&
    `
    grid-template-columns: repeat(4, 1fr);
    gap: 15px;

  `};

  ${mediaQuery.isTablet} {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const CameraStatus = styled.li<{ status: string }>`
  color: ${({ theme }) => theme.COLORS.WHITE};
  ${({ status, theme }) =>
    status === "online" &&
    `
    color: ${theme.value === "light" ? COLORS.BUTTONS2 : COLORS.GREEN};
  `};
  ${({ status }) =>
    status === "offline" &&
    `
    color: ${COLORS.LIGHTGRAY};
  `};
  ${({ status }) =>
    status === "alert" &&
    `
    color: ${COLORS.VALIDATERED};
  `};
  ${({ status }) =>
    status === "videoLoss" &&
    `
    color: ${COLORS.VALIDATERED};
  `};

  ${FLEX.FlexStartCenter};
  gap: 6px;
  height: max-content;

  .content {
    ${FLEX.FlexAroundCenter};
    flex-direction: column;
    gap: 1px;
    font-size: ${calcRem(11)};
  }

  .icon {
    position: relative;
    width: 35px;
    height: max-content;

    svg {
      width: 100%;
    }

    > span {
      position: absolute;
      top: 6.5px;
      left: 50%;
      transform: translateX(-50%);
      font-size: ${calcRem(10)};
    }
  }
`;

export const ActiveLicenseMap = styled.ul`
  width: 100%;
  ${FLEX.FlexStartStart};
  flex-direction: column;
  font-size: ${calcRem(14)};

  > li {
    width: 100%;
    border-bottom: 1px solid ${COLORS.BORDER};
    ${FLEX.FlexBetweenStart};
    padding: 8px 0;
    gap: 10px;

    > div {
      flex: 1.5;
      ${FLEX.FlexStartStart};
      flex-direction: column;
      gap: 8px;

      .expire {
        color: ${COLORS.BUTTONS1};
      }
    }

    .type {
      flex: 1;
    }
    /* .licenseKey {
      flex: 1.5;
    } */
  }
`;
