import { Text } from "components/atoms/text/Text";
import { COLORS } from "styles/colors";
import SmallPopupDefaultTemplate from "components/templates/modal/small/SmallPopupDefaultTemplate";
import BigButton from "components/atoms/buttons/BigButton";
import styled from "styled-components";
import { calcRem } from "styles/theme";
import { FLEX } from "styles/flex";
import { useState } from "react";
import * as s from 'styles/ModalCommonStyled';

type NormalConfirmModalLabel = "install" | "techSupport" | "screenRecording" | undefined;

type ContentType = {
  label: NormalConfirmModalLabel,
  option: any,
  setOption: (value: any) => void,
}

const Content = ({ label, option, setOption }: ContentType): JSX.Element => {
  switch (label) {
    case "install": {
      return (<>
        <Text fontSize={20} bold>
          Do you really want to finish this install?
        </Text>
        <Text fontSize={20} bold color={COLORS.COMPANY}>
          The finished installation report cannot be changed.
        </Text>
      </>);
    }

    case "techSupport": {
      return (<>
        <Text fontSize={20} bold>
          Do you really want to close this submit{" "}
        </Text>
      </>);
    }

    case "screenRecording": {
      return (<>
        <Text fontSize={20} bold>
          Press "Yes" to start recording the screen.
        </Text>
        <br />
        <Text fontSize={18} color={COLORS.COMPANY}>
          - The maximum recording time is 5 minutes.<br />
          - Don't resize the window during recording.
        </Text>
        <br />
        <s.CheckInput style={{ justifyContent: "center" }}>
          <input
            type="checkbox"
            id="dontshowagain"
            checked={option}
            onChange={(e) => {
              setOption(e.target.checked);
            }}
          />
          <Text fontSize={14}>
            <label htmlFor="dontshowagain">Dont show this message again</label>
          </Text>
        </s.CheckInput>
      </>)
    }

    default: {
      return <></>
    }
  }
}

type Props = {
  onConfirmCancel: () => void;
  onConfirm: (option?: any) => void;
  label?: NormalConfirmModalLabel;
};

export default function NormalConfirmModal({
  onConfirmCancel,
  onConfirm,
  label,
}: Props): JSX.Element {
  const [option, setOption] = useState<any>(null);

  return (
    <SmallPopupDefaultTemplate
      onModal={onConfirmCancel}
      button={
        <ButtonWrapper>
          <BigButton buttonType="cancel" label="No" onClickBtn={onConfirmCancel} />
          <BigButton label="Yes" onClickBtn={() => onConfirm(option)} />
        </ButtonWrapper>
      }
    >
      <TextWrapper>
        <Content label={label} option={option} setOption={setOption} />
      </TextWrapper>
    </SmallPopupDefaultTemplate>
  );
}
export const TextWrapper = styled.div`
  text-align: center;
  padding: 20px 0;
  line-height: ${calcRem(28)};
`;

const ButtonWrapper = styled.div`
  ${FLEX.FlexCenterCenter};
  gap: 10px;
`;
