import { useAuth } from "components/Auth";
import { Fragment, forwardRef, useCallback, useEffect, useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";

import * as s from "./SubStyled";
import { setMenu } from "redux/reducers/menu/menuSlice";
import { onOpen } from "redux/reducers/sidenav/SideNavReducer";

import { IoIosCopy, IoMdSettings } from "react-icons/io";
import { COLORS } from "styles/colors";
import { handleCopyClipBoard } from "utils/functions";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { menuNavigation } from "utils/MenuUtil";
import { PiRecordFill, PiSignOut } from "react-icons/pi";
import { GoArrowSwitch } from "react-icons/go";
import { BiHelpCircle } from "react-icons/bi";

const SCREEN_RECORDER_SUPPORTED = !!window.navigator.mediaDevices.getDisplayMedia;

interface MenuItem {
  label: string,
  icon: JSX.Element,
  disabled?: boolean,
  onClick: () => void,
  visible: boolean,
}

interface Props {
  onChangeSwitch: () => void;
  isScreenRecording: boolean;
  onConfirmScreenRecording: () => void;
}

const UserPopup = forwardRef((props: Props, ref: any): JSX.Element => {
  const navigate = useNavigate();
  const auth = useAuth();

  const dispatch = useAppDispatch();
  const [totalAccount, setTotalAccount] = useState<number>(
    auth.user.totalAccount
  );

  const isOpen: boolean = useAppSelector(
    (state: any) => state.sidenavOpen.isOpen
  );
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );

  useEffect(() => {
    setTotalAccount(auth.user.totalAccount);
  }, [auth.user.totalAccount]);

  const onClickLoutout = useCallback(() => {
    /**
     * @todo clear redux store
     */
    auth.logout();
    // navigate("/login", { replace: true });
  }, [auth, navigate]);

  const iconSize = "20px";

  const menus: MenuItem[] = [
    {
      label: "Switch Organization",
      icon: <GoArrowSwitch size={iconSize} />,
      onClick: props.onChangeSwitch,
      visible: totalAccount > 1,
    },
    {
      label: "Profile Settings",
      icon: <IoMdSettings size={iconSize} />,
      onClick: () => {
        dispatch(setMenu("Settings"));
        menuNavigation(selectedAccount, navigate, "/settings/myaccount");
      },
      visible: true,
    },
    {
      label: "Screen Recording",
      icon: <PiRecordFill size={iconSize} />,
      disabled: props.isScreenRecording,
      onClick: props.onConfirmScreenRecording,
      visible: SCREEN_RECORDER_SUPPORTED,
    },
    {
      label: "Help",
      icon: <BiHelpCircle size={iconSize} />,
      onClick: () => {
        dispatch(setMenu("Help"));
        menuNavigation(selectedAccount, navigate, "/help/videoguides");
        //navigate("/help/videoguides");
      },
      visible: true,
    },
    {
      label: "Sign Out",
      icon: <PiSignOut size={iconSize} />,
      onClick: onClickLoutout,
      visible: true,
    },
  ].filter((menu) => menu.visible);

  return (
    <>
      <s.PopupWrapper key={"userPopupWrapper"} ref={ref}>
        <s.AccountId>
          <span>Organization ID</span>
          <span>
            {selectedAccount.accountNumber}{" "}
            <button
              onClick={(e) => {
                // e.preventDefault();
                e.stopPropagation();
                handleCopyClipBoard(selectedAccount.accountNumber);
              }}
            >
              <IoIosCopy color={COLORS.BORDER} />
            </button>
          </span>
        </s.AccountId>
        {menus.map((menu, index) => {
          return (
            <Fragment key={index}>
                {index === menus.length - 1 && <s.Line></s.Line>}
                <s.PopupMenu
                  disabled={menu.disabled || false}
                  key={menu.label}
                  onClick={(e) => {
                    if(menu.disabled) {
                      e.stopPropagation();
                      return;
                    }
                    menu.onClick();
                    dispatch(onOpen(!isOpen));
                  }}
                >
                  {menu.icon}
                  {menu.label}
                </s.PopupMenu>
              </Fragment>
          );
        }
        )}
      </s.PopupWrapper>
    </>
  );
});

export default UserPopup;
