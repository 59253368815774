// import ARROWDOWN from "assets/icons/arrow_down.svg";
import Select, { OptionProps, StylesConfig } from "react-select";
import { COLORS, LIGHT_THEME_COLORS } from "styles/colors";
import { Option } from "./util";
import { useAppSelector } from "redux/hooks";
import { store } from "redux/store";
import * as mediaQuery from "components/MediaQuery";
import { useLocation } from "react-router-dom";
import { customDashboardSelectStyles } from "pages/dashboard/dashboard/item/table/AccountSelects";

type OptionsType = Array<Option>;

type CommonProps = {
  // clearValue: () => void,
  // getStyles: (string, any) => {},
  // getValue: () => ValueType,
  // hasValue: boolean,
  isMulti?: boolean;
  options: OptionsType;

  // selectOption: OptionType => void,
  // selectProps: any,
  // setValue: (ValueType, ActionTypes) => void,
  // emotion: any,
  value?: any;
  defaultValue?: any;
  isDisabled?: boolean;
  onChange?: (value: string) => void;
  placeholder?: string;
  id?: string;
};

export const customStyles: StylesConfig<Option> = {
  control: (styles, state) => {
    let isLightTheme =
      state.selectProps.className?.includes("select-light-mode");
    // console.log(styles, state.selectProps.className, isLightTheme);
    const backgroundColor = state.isDisabled
      ? isLightTheme
        ? LIGHT_THEME_COLORS.LIGHTGRAY
        : COLORS.BUTTONS1
      : COLORS.WHITE;
    const borderColor =
      state.isDisabled && isLightTheme ? LIGHT_THEME_COLORS.BORDER : "#cacaca";
    const maxHeight = "33px";
    const minHeight = "33px";
    const fontWeight = "normal";

    return {
      ...styles,
      backgroundColor,
      borderColor,
      maxHeight,
      minHeight,
      fontWeight,
    };
  },
  container: (styles, state) => {
    let isLightTheme =
      state.selectProps.className?.includes("select-light-mode");

    return {
      ...styles,
      width: "100%",
      color: state.isDisabled
        ? isLightTheme
          ? `${COLORS.BLACK}60`
          : COLORS.WHITE
        : COLORS.BLACK,
      fontSize: 14,
      fontWeight: "normal",
    };
  },
  singleValue: (styles, state) => {
    let isLightTheme =
      state.selectProps.className?.includes("select-light-mode");

    return {
      ...styles,
      color: state.isDisabled
        ? isLightTheme
          ? `${COLORS.BLACK}60`
          : `${COLORS.WHITE}60`
        : // : COLORS.WHITE
          COLORS.BLACK,
      fontWeight: "normal",
      lineHeight: "18px",
    };
  },
  menuPortal: (provided, state) => {
    // console.log(state.selectProps?.className?.incldues("isDashboard"));

    return { ...provided, zIndex: 9999 };
  },
  placeholder: (styles) => ({ ...styles, color: "#abb5c5" }),
  indicatorSeparator: () => ({}),
  indicatorsContainer: (styles) => ({ ...styles, padding: 0 }),
  dropdownIndicator: (styles, state) => {
    let isLightTheme =
      state.selectProps.className?.includes("select-light-mode");

    return {
      ...styles,
      padding: 0,
      color:
        state.isDisabled && isLightTheme ? COLORS.LIGHTGRAY : COLORS.BUTTONS1,
    };
  },
};

const customMobileStyles: StylesConfig<Option> = {
  control: (styles, { isDisabled }) => {
    // console.log(styles);
    const backgroundColor = isDisabled ? COLORS.BUTTONS1 : COLORS.WHITE;
    const borderColor = "#cacaca";
    const maxHeight = "30px";
    const minHeight = "30px";
    const maxWidth = "140px";

    return {
      ...styles,
      backgroundColor,
      borderColor,
      maxHeight,
      minHeight,
    };
  },
  container: (styles, { isDisabled }) => ({
    ...styles,
    width: "100%",
    color: isDisabled ? COLORS.WHITE : COLORS.BLACK,
    fontSize: 12,
    // maxWidth: "140px",
  }),
  singleValue: (styles, { isDisabled }) => ({
    ...styles,
    color: isDisabled ? COLORS.LIGHTGRAY : COLORS.BLACK,
  }),
  menuPortal: (provided) => {
    return { ...provided, zIndex: 9999 };
  },
  placeholder: (styles) => ({ ...styles, color: "#abb5c5" }),
  indicatorSeparator: () => ({}),
  indicatorsContainer: (styles) => ({ ...styles, padding: 0 }),
  dropdownIndicator: (styles) => ({
    ...styles,
    padding: 0,
    color: COLORS.BUTTONS1,
  }),
};

const Selects = (props: CommonProps) => {
  const theme = useAppSelector((state) => state.theme.theme);
  const location = useLocation();

  const isDashboard = location.pathname.includes("dashboard/chart");

  return (
    <>
      <mediaQuery.Default>
        <Select
          id={props.id}
          {...props}
          // theme={theme}
          styles={isDashboard ? customDashboardSelectStyles : customStyles}
          menuPortalTarget={document.querySelector("body")}
          placeholder={props.placeholder ? props.placeholder : "Select"}
          onChange={({ value }) => {
            if (props.onChange) {
              props.onChange(value);
            }
          }}
          hideSelectedOptions={
            props.options !== undefined && props.options.length === 1
          }
          isDisabled={
            props.isDisabled ||
            (props.options !== undefined && props.options.length === 1)
          }
          components={
            props.options !== undefined && props.options.length === 1
              ? {
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }
              : {}
          }
          isSearchable={false}
          className={`select-container select-${theme}-mode `}
        />
      </mediaQuery.Default>
      <mediaQuery.Mobile>
        <Select
          id={props.id}
          {...props}
          // theme={theme}
          styles={customMobileStyles}
          menuPortalTarget={document.querySelector("body")}
          placeholder={props.placeholder ? props.placeholder : "Select"}
          onChange={({ value }) => {
            if (props.onChange) {
              props.onChange(value);
            }
          }}
          isDisabled={
            props.isDisabled ||
            (props.options !== undefined && props.options.length === 1)
          }
          hideSelectedOptions={
            props.options !== undefined && props.options.length === 1
          }
          components={
            props.options !== undefined && props.options.length === 1
              ? {
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }
              : {}
          }
          isSearchable={false}
        />
      </mediaQuery.Mobile>
    </>
  );
  // return (
  //   <Select
  //     id={props.id}
  //     {...props}
  //     // theme={theme}
  //     styles={customStyles}
  //     menuPortalTarget={document.querySelector("body")}
  //     placeholder={props.placeholder ? props.placeholder : "Select"}
  //     onChange={({ value }) => {
  //       if (props.onChange) {
  //         props.onChange(value);
  //       }
  //     }}
  //   />
  // );
};

export default Selects;
