import { Table } from "components/atoms/table/Table";
import { SortOrder, TableColumn } from "react-data-table-component";
import { TableTemplate } from "components/templates/default/table/TableTemplate";
import Status, { StatusType } from "components/blocks/status/Status";
import { AlertHistoryList } from "api/interfaces/alertInterface.interface";
import { TitleComponentArrayType } from "components/blocks/subheader/SubHeader";
import { ClickSearch } from "components/atoms/input/search/SearchInput";
import * as mediaQuery from "components/MediaQuery";
import { BasePagination } from "api/interfaces/commonInterface.interface";

type Props = {
  data: AlertHistoryList[];
  columns: TableColumn<AlertHistoryList>[];
  mobileColumns: TableColumn<AlertHistoryList>[];
  status: StatusType;
  onRowClick: (row: AlertHistoryList) => void;
  titleComponent: React.ReactNode | TitleComponentArrayType[];
  onClickSearch: ClickSearch;
  paginationPerPage: number;
  onChangePage: (page: number, totalRows: number) => void;
  handleSort: (
    selectedColumn: TableColumn<any>,
    sortDirection: SortOrder
  ) => void;
  paginationTotalRows: number;
  paginationInfo: BasePagination;
  isPending: boolean;
};

export default function AlertHistoryPresenter(props: Props): JSX.Element {
  const centerChildren = (
    <>
      <Status status={props.status} />
    </>
  );
  return (
    <>
      <TableTemplate
        titleComponent={props.titleComponent}
        centerChildren={centerChildren}
        onClickSearch={props.onClickSearch}
        keyword={props.paginationInfo.keyword}
      >
        <mediaQuery.Default>
          <Table
            columns={props.columns}
            data={props.data}
            onRowClicked={props.onRowClick}
            paginationTotalRows={props.paginationTotalRows}
            paginationPerPage={props.paginationPerPage}
            handleSort={props.handleSort}
            onChangePage={props.onChangePage}
            defaultSortFieldId={"merged_system_name"}
            isRowClickEditable={true}
            progressPending={props.isPending}
          />
        </mediaQuery.Default>
        <mediaQuery.Mobile>
          <Table
            columns={props.mobileColumns}
            data={props.data}
            onRowClicked={props.onRowClick}
            paginationTotalRows={props.paginationTotalRows}
            paginationPerPage={props.paginationPerPage}
            handleSort={props.handleSort}
            onChangePage={props.onChangePage}
            defaultSortFieldId={"merged_system_name"}
            isRowClickEditable={true}
          />
        </mediaQuery.Mobile>
      </TableTemplate>
    </>
  );
  // }
}
