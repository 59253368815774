import { TableTemplate } from "components/templates/default/table/TableTemplate";
import { Outlet } from "react-router-dom";
import { useCallback, useState, useEffect } from "react";
import { useQueryClient } from "react-query";
import { useAppSelector } from "redux/hooks";
import {
  LEVEL_TYPE,
  ProfileAccountInfo,
} from "api/interfaces/accountInterface.interface";
import { AlertActiveSummary } from "api/interfaces/alertInterface.interface";
import {
  SORT_DIRECTION,
  BasePagination,
} from "api/interfaces/commonInterface.interface";
import { Location, useLocation, useNavigate } from "react-router-dom";

import * as s from "./AlertStyled";
import { COLORS } from "styles/colors";
import { StatusName, StatusValue } from "components/blocks/status/Status";
import { isNull } from "lodash";
import { menuNavigation, queryNavigate } from "utils/MenuUtil";
import Status from "components/blocks/status/Status";
import { TitleComponentArrayType } from "components/blocks/subheader/SubHeader";

export const InitialAlertSummary = {
  total: 0,
  systemCount: 0,
  diskCount: 0,
  videoCount: 0,
};
export default function AlertPage(): JSX.Element {
  const navigate = useNavigate();
  const location: Location = useLocation();

  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );

  const queryClient = useQueryClient();
  const isAlertView = location.pathname.includes("view");

  const [pending, setPending] = useState<boolean>(true);

  const [totalCount, setTotalCount] = useState<number>(0);

  const [title, setTitle] = useState<string>("");
  const [alertSummary, setAlertSummary] =
    useState<AlertActiveSummary>(InitialAlertSummary);

  const [alertActiveQueryInfo, setAlertActiveQueryInfo] =
    useState<BasePagination>({
      total: 0,
      pageNum: 0,
      pageLimit: 20,
      keyword: "",
      sortType: "alert_trigger",
      type: null,
      status: null,
      sortDirection: SORT_DIRECTION.DESC,
    });

  useEffect(() => {
    const changeParam = new URLSearchParams(location.search);
    if (changeParam.size > 0) {
      setAlertActiveQueryInfo((info) => {
        return {
          ...info,
          pageNum: isNull(changeParam.get("pageNum"))
            ? 0
            : Number(changeParam.get("pageNum")),
          pageLimit: isNull(changeParam.get("pageLimit"))
            ? 20
            : Number(changeParam.get("pageLimit")),
          keyword: isNull(changeParam.get("keyword"))
            ? ""
            : (changeParam.get("keyword") as string),
          sortType: isNull(changeParam.get("sortType"))
            ? "alert_trigger"
            : (changeParam.get("sortType") as string),
          sortDirection: isNull(changeParam.get("sortDirection"))
            ? SORT_DIRECTION.DESC
            : (changeParam.get("sortDirection") as SORT_DIRECTION),
          type: isNull(changeParam.get("type"))
            ? null
            : (changeParam.get("type") as string),
          status: isNull(changeParam.get("status"))
            ? null
            : (changeParam.get("status") as string),
        };
      });
    } else {
      setAlertActiveQueryInfo((info) => {
        return {
          ...info,
          pageNum: 0,
          pageLimit: 20,
          keyword: "",
          sortType: "alert_trigger",
          sortDirection: SORT_DIRECTION.DESC,
        };
      });
    }
  }, [location]);
  //status
  const status = {
    totalTitle: "Total",
    totalValue: alertSummary.total,
    items: [
      <>
        <s.Label>
          <StatusName>System</StatusName>
        </s.Label>
        <StatusValue color={COLORS.RED}>{alertSummary.systemCount}</StatusValue>
      </>,
      <>
        <s.Label>
          <StatusName>Disk</StatusName>
        </s.Label>
        <StatusValue color={COLORS.RED}>{alertSummary.diskCount}</StatusValue>
      </>,
      <>
        <s.Label>
          <StatusName>Video</StatusName>
        </s.Label>
        <StatusValue color={COLORS.RED}>{alertSummary.videoCount}</StatusValue>
      </>,
    ],
    isMobileShow: {
      totalTitle: true,
      totalValue: true,
      items: false,
    },
  };

  const onClickSearch = useCallback(
    (keyword: string) => {
      if (alertActiveQueryInfo.keyword !== keyword) {
        queryNavigate(
          navigate,
          location.pathname,
          {
            pageLimit: alertActiveQueryInfo.pageLimit,
            pageNum: 0,
            keyword: keyword,
            sortType: alertActiveQueryInfo.sortType,
            sortDirection: alertActiveQueryInfo.sortDirection as SORT_DIRECTION,
          },
          selectedAccount
        );
      } else {
        queryClient.invalidateQueries("activeAlert");
      }
      setPending(true);
    },
    [
      alertActiveQueryInfo.keyword,
      alertActiveQueryInfo.pageLimit,
      alertActiveQueryInfo.sortDirection,
      alertActiveQueryInfo.sortType,
      location.pathname,
      navigate,
      selectedAccount,
    ]
  );

  const onChangeTitle = (recorderName: string) => {
    setTitle(recorderName);
  };

  const onChangeStatusAlertSummary = (alertStatus: AlertActiveSummary) => {
    setAlertSummary(alertStatus);
  };

  const titleComponent: TitleComponentArrayType[] = [
    {
      label: "Alert",
      onClick: () => menuNavigation(selectedAccount, navigate, "/alert/active"),
    },
    {
      label: title,
      isNoshow: !isAlertView,
    },
  ];
  const centerChildren = <Status status={status} />;
  const outletContext = {
    totalCount,
    setTotalCount,
    pending,
    setPending,
    onChangeTitle,
    onChangeStatusAlertSummary,
  };

  return (
    <TableTemplate
      onClickSearch={onClickSearch}
      centerChildren={centerChildren}
      titleComponent={titleComponent}
      keyword={alertActiveQueryInfo.keyword}
      requiredPermissions={
        selectedAccount.accountLevel === LEVEL_TYPE.DW ? "" : "manageAlertRules"
      }
      noSearch={isAlertView}
    >
      <Outlet context={outletContext} />
    </TableTemplate>
  );
}
