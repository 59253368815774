import {
  Interval,
  RecorderHealthMoreDetailInterface,
  RecorderHealthRow,
  ReportHealthDetailCloudStorage,
  ReportHealthDetailUser,
} from "api/interfaces/reportInterface.interface";
import styled from "styled-components";
import { COLORS } from "styles/colors";
import { FLEX } from "styles/flex";
import { calcRem } from "styles/theme";
import Health from "./type/Health";
import Cameras from "./type/Cameras";
import Performance from "./type/Performance";
import RemoteAccess from "./type/RemoteAccess";
import SystemSpecs from "./type/systemspecs";
import Storage from "./type/Storage";
import * as mediaQuery from "components/MediaQuery";
import {
  StatusLabel,
  StatusName,
  StatusValue,
} from "components/blocks/status/Status";
import {
  ReportHealthDetailCamera,
  ReportHealthDetailPerformance,
  ReportHealthDetailRecorder,
  ReportHealthDetailRemote,
  ReportHealthDetailStorage,
} from "api/interfaces/reportInterface.interface";
import ReportLink from "components/blocks/link/ReportLink";
import { RecorderDetail } from "../HealthDetail";
import { getRecorderTimeFormat } from "utils/timeUtil";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { useAppSelector } from "redux/hooks";

type Props = {
  accountId: string;
  reportId: string;
  recorderId: string;
  recorderInfo: RecorderDetail;
  menus: Array<RecorderHealthMoreDetailInterface>;
  isCurrentMenu: number;
  recorderHealth: ReportHealthDetailRecorder;
  cameraHealth: ReportHealthDetailCamera;
  userHealth: ReportHealthDetailUser;
  performance: ReportHealthDetailPerformance;
  remoteAccess: ReportHealthDetailRemote;
  storages: ReportHealthDetailStorage[];
  cloudStorage: ReportHealthDetailCloudStorage;
  performanceIntervalValue: Interval;
  onChangeIntervalOption: (e: any) => void;
  lastUpdateTime: string;
};

export default function HealthContent(props: Props): JSX.Element {
  const currentMenu = props.menus[props.isCurrentMenu];
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );
  // props.isCurrentMenu 와 contentList index 와 일치한 내용이 보이게 된다.
  const contentList = [
    <Health data={props.recorderHealth} recorderInfo={props.recorderInfo} />,
    <Cameras data={props.cameraHealth} />,
    <Performance
      data={props.performance}
      reportId={props.reportId}
      recorderId={props.recorderId}
      performanceIntervalValue={props.performanceIntervalValue}
      onChangeIntervalOption={props.onChangeIntervalOption}
    />,
    <RemoteAccess
      data={props.remoteAccess}
      userData={props.userHealth}
      reportId={props.reportId}
      recorderId={props.recorderId}
    />,
    <SystemSpecs
      recorderId={props.recorderInfo.recorderId}
      type={props.recorderInfo.type}
    />,
    <Storage
      recorderId={props.recorderId}
      // data={props.storages}
      // cloudStorage={props.cloudStorage}
      // cameraData={props.cameraHealth}
    />,
  ];

  const titleList = [
    <StatusLabel>
      <StatusName>Total Alerts</StatusName>
      <StatusValue color={COLORS.PROGRESSBAR}>
        {props.recorderHealth.totalAlerts}
      </StatusValue>
    </StatusLabel>,
    <>
      <StatusLabel>
        <StatusName>Video Loss</StatusName>
        <StatusValue color={COLORS.PROGRESSBAR}>
          {props.cameraHealth.videoLoss}
        </StatusValue>
      </StatusLabel>
      <StatusLabel>
        <StatusName>Total</StatusName>
        <StatusValue>{props.cameraHealth.totalCamera}</StatusValue>
      </StatusLabel>
      {props.lastUpdateTime !== "" && (
        <StatusName>{`Last Update : ${getRecorderTimeFormat(
          props.lastUpdateTime,
          selectedAccount,
          props.recorderInfo.timezone,
          true
        )} `}</StatusName>
      )}
    </>,
    <>
      <StatusLabel>
        <StatusName>CPU</StatusName>
        <StatusValue color={COLORS.BUTTONS2}>
          {props.performance.cpuPercentage}%
        </StatusValue>
      </StatusLabel>
      <StatusLabel>
        <StatusName>Memory</StatusName>
        <StatusValue color="#03efe1">
          {props.performance.memoryPercentage}%
        </StatusValue>
      </StatusLabel>
    </>,
    <></>,
    <>{/* <ReportLink type={"inventory"} accountId={props.accountId} /> */}</>,
    <StatusLabel>
      {/* <StatusValue color={COLORS.BUTTONS2}>15</StatusValue> */}
      {/* <StatusName>Days of Footage Recorded</StatusName> */}
    </StatusLabel>,
  ];

  return (
    <Container>
      <mediaQuery.Default>
        <Title>
          <li className="left">
            <span className="label">
              {currentMenu.contentLabel !== undefined
                ? currentMenu.contentLabel
                : currentMenu.label}
            </span>
          </li>
          <li className="right">{titleList[props.isCurrentMenu]}</li>
        </Title>
      </mediaQuery.Default>

      <ContentsWrapper>{contentList[props.isCurrentMenu]}</ContentsWrapper>
    </Container>
  );
}

const Container = styled.section`
  background-color: ${({ theme }) =>
    theme.value === "light" ? COLORS.WHITE : "#0e1722"};

  ${({ theme }) =>
    theme.value === "light" &&
    `
    border: 1px solid ${theme.COLORS.BORDER};
  `}

  border-radius: 5px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex: 1;

  h5 {
    color: ${({ theme }) => theme.COLORS.WHITE};
  }

  ${mediaQuery.isDefault} {
    padding: 20px;
  }

  ${mediaQuery.isMobile} {
    padding: 0 10px;
  }
`;

const Title = styled.ul`
  padding: 15px 0;
  border-bottom: 1px solid ${COLORS.BORDER};
  color: ${({ theme }) => theme.COLORS.WHITE};
  ${FLEX.FlexStartEnd};
  width: 100%;
  gap: 50px;

  li {
    ${FLEX.FlexStartCenter};
    gap: 18px;
  }

  .label {
    font-size: ${calcRem(18)};
    font-weight: 500;
  }
`;

const ContentsWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex: 1;
  .select-wrapper {
    width: 25%;
    min-width: 120px;
    max-width: 120px;
  }
`;
