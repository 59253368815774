import { Table } from "components/atoms/table/Table";
import { SortOrder, TableColumn } from "react-data-table-component";
import { TableTemplate } from "components/templates/default/table/TableTemplate";

import { ClickSearch } from "components/atoms/input/search/SearchInput";
import { CreateAccountRequest } from "api/interfaces/accountInterface.interface";

import AddModal from "pages/account/add";
import EditModal from "pages/account/edit";
import DeactivateModal from "pages/setting/myProfile/modal/deactivate";
import { LicenseAccountList } from "api/interfaces/licenseInterface.interface";
import { Account } from "api/mappers/accountMapper";
import {
  BasePagination,
  Validate,
  ValidateMsg,
} from "api/interfaces/commonInterface.interface";
import Status, { StatusType } from "components/blocks/status/Status";
type Props = {
  isAddModal: boolean;
  isEditModal: boolean;
  isCheckSuspend: boolean;
  onModal: (type?: string) => void;
  columns: TableColumn<LicenseAccountList>[];
  data: Array<LicenseAccountList>;
  accountInfo: Account | undefined;
  accountValidate: Validate;
  accountValidateMsg: ValidateMsg;
  onClickSearch: ClickSearch;
  onClickCreate: (accountInfo: CreateAccountRequest) => void;
  onChangeIsSuspend: () => void;
  onCellClick: (row: LicenseAccountList) => void;
  onClickUpdate: (account: Account, file: File) => void;
  onClickDelete: (accountId: string) => void;
  onConfirmSuspend: () => void;
  initialPending: boolean;
  onChangePage: (page: number, totalRows: number) => void;
  paginationInfo: BasePagination;
  status: StatusType;
  isSelf: boolean;
  isCreateLoading: boolean;
  isUpdateLoading: boolean;
  handleSort: (
    selectedColumn: TableColumn<any>,
    sortDirection: SortOrder
  ) => void;
};

export default function BillingAccountPresenter({
  isAddModal,
  isEditModal,
  isCheckSuspend,
  onModal,
  columns,
  data,
  accountInfo,
  accountValidate,
  accountValidateMsg,
  onClickSearch,
  onClickCreate,
  onChangeIsSuspend,
  onCellClick,
  onClickUpdate,
  onClickDelete,
  onConfirmSuspend,
  initialPending,
  onChangePage,
  paginationInfo,
  status,
  isSelf,
  isCreateLoading,
  isUpdateLoading,
  handleSort
}: Props): JSX.Element {
  return (
    <>
      <TableTemplate
        titleComponent="Customer Organization"
        // onClickBtn={isSelf ? undefined : () => onModal("add")}
        // buttonLabel={isSelf ? undefined : "Add Organization"}
        onClickSearch={onClickSearch}
        centerChildren={<Status status={status} />}
        keyword={paginationInfo.keyword}
        isSelf={isSelf}
        requiredPermissions={"manageAccount"}
      >
        <Table
          columns={columns}
          data={data}
          onRowClicked={onCellClick}
          paginationTotalRows={status.totalValue as number}
          defaultSortFieldId={"name"}
          progressPending={initialPending}
          onChangePage={onChangePage}
          handleSort={handleSort}
        />
      </TableTemplate>
      {isAddModal && (
        <AddModal
          onModal={() => onModal("add")}
          onClickCreate={onClickCreate}
          accountValidate={accountValidate}
          accountValidateMsg={accountValidateMsg}
          isCreateLoading={isCreateLoading}
        />
      )}
      {isEditModal && accountInfo !== undefined && (
        <EditModal
          onModal={() => onModal("edit")}
          accountInfo={accountInfo}
          onClickSave={onClickUpdate}
          onClickDelete={onClickDelete}
          isUpdateLoading={isUpdateLoading}
        />
      )}
      {isCheckSuspend && (
        <DeactivateModal
          onDeactiveModal={onChangeIsSuspend}
          onConfirm={onConfirmSuspend}
          title="Do you really want to suspend this organization?"
          subTitle="Associated organization will also be suspended."
        />
      )}
    </>
  );
}
