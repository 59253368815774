import { AxiosResponse } from "axios";
import qs from "qs";
import { AxiosInstance } from "./axiosInstance";
import {
  GetReportListResposne,
  GetReportRequest,
  HealthReport,
  InstallReport,
  InventoryReport,
  CVVReport,
  ThumbnailReport,
  CommonReportResponse,
  GetReportHealthList,
  GetReportHealthDetail,
  getReportInfoResponse,
  GetReportInventoryCameraList,
  GetReportInvetoryRecorderList,
  getReoprtDetailPerformanceResponse,
  Interval,
  getReoprtDetailRemoteAccessResponse,
  getReoprtDetailUserResponse,
  InstallationViewResponse,
  ThumbnailDateTimeResponse,
  ThumbnailQueryString,
  ThumbnailViewResponse,
  ThumbnailRecorderResponse,
  ThumbnailCameraResponse,
  ThumbnailFilterDateResponse,
  ThumbnailFilterQueryString,
  ThumbnailViewFilterResponse,
  CvvQueryString,
  CvvViewResponse,
  CvvRecorderResponse,
  CVVReferenceUpdateReq,
  SendEmailReportResponse,
  ExportEmailPayLoad,
  getPreferenceResponse,
  SpectrumSystemStatusRes,
  SystemUsageRes,
  VmaxSystemStatusRes,
  ReportHealthDetailStorageResponse,
  SpectrumSystemStatsRes,
} from "./interfaces/reportInterface.interface";
import {
  BasePagination,
  CommonResponse,
  CustomError,
} from "./interfaces/commonInterface.interface";

const API_PATH = "/report";

export const getReportList = async ({
  payload,
  accountId,
}: {
  payload: GetReportRequest;
  accountId: string;
}): Promise<GetReportListResposne> => {
  try {
    const res: AxiosResponse<GetReportListResposne> = await AxiosInstance.get(
      `${API_PATH}/list/${accountId}?${qs.stringify(payload)}`
    );
    return res.data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const postHealthReportCreate = async ({
  payload,
  accountId,
}: {
  payload: HealthReport;
  accountId: string;
}): Promise<CommonReportResponse> => {
  try {
    const res: AxiosResponse<CommonReportResponse> = await AxiosInstance.post(
      `${API_PATH}/create/health/${accountId}`,
      payload
    );
    if (res.data.error !== 0) throw new Error();
    return res.data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const putHealthReportCreate = async ({
  payload,
  accountId,
  reportId,
  type,
}: {
  payload: HealthReport;
  accountId: string;
  reportId: string;
  type: string;
}): Promise<CommonReportResponse> => {
  try {
    const res: AxiosResponse<CommonReportResponse> = await AxiosInstance.put(
      `${API_PATH}/edit/${type}/${accountId}/${reportId}`,
      payload
    );
    if (res.data.error !== 0) throw new Error();
    return res.data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const putReportEnable = async ({
  reportId,
  payload,
}: {
  reportId: string;
  payload: { enable: boolean };
}): Promise<CommonReportResponse> => {
  try {
    const res: AxiosResponse<CommonReportResponse> = await AxiosInstance.put(
      `${API_PATH}/update/${reportId}/isEnable`,
      payload
    );
    if (res.data.error !== 0) throw new Error();
    return res.data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const deleteReport = async ({
  reportId,
  accountId,
}: {
  reportId: string;
  accountId: string;
}): Promise<void> => {
  try {
    const res: AxiosResponse<void> = await AxiosInstance.delete(
      `${API_PATH}/delete/${accountId}/${reportId}`
    );
    if (res.status !== 200) throw new Error();
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const postCVVReportCreate = async ({
  payload,
  accountId,
}: {
  payload: CVVReport;
  accountId: string;
}): Promise<void> => {
  try {
    const res: AxiosResponse<CommonReportResponse> = await AxiosInstance.post(
      `${API_PATH}/create/cvv/${accountId}`,
      payload
    );
    if (res.data.error !== 0) throw new Error();
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const putCVVReportCreate = async ({
  payload,
  accountId,
  reportId,
}: {
  payload: CVVReport;
  accountId: string;
  reportId: string;
}): Promise<void> => {
  try {
    const res: AxiosResponse<CommonReportResponse> = await AxiosInstance.put(
      `${API_PATH}/edit/cvv/${accountId}/${reportId}`,
      payload
    );
    if (res.data.error !== 0) throw new Error();
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const postThumbnailReportCreate = async ({
  payload,
  accountId,
}: {
  payload: ThumbnailReport;
  accountId: string;
}): Promise<void> => {
  try {
    const res: AxiosResponse<CommonReportResponse> = await AxiosInstance.post(
      `${API_PATH}/create/thumbnail/${accountId}`,
      payload
    );
    if (res.data.error !== 0) throw new Error();
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const putThumbnailReportCreate = async ({
  payload,
  accountId,
  reportId,
}: {
  payload: ThumbnailReport;
  accountId: string;
  reportId: string;
}): Promise<void> => {
  try {
    const res: AxiosResponse<CommonReportResponse> = await AxiosInstance.put(
      `${API_PATH}/edit/thumbnail/${accountId}/${reportId}`,
      payload
    );
    if (res.data.error !== 0) throw new Error();
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const postInventoryReportCreate = async ({
  payload,
  accountId,
}: {
  payload: InventoryReport;
  accountId: string;
}): Promise<void> => {
  try {
    const res: AxiosResponse<CommonReportResponse> = await AxiosInstance.post(
      `${API_PATH}/create/inventory/${accountId}`,
      payload
    );
    if (res.data.error !== 0) throw new Error();
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const postInstallReportCreate = async ({
  payload,
  accountId,
}: {
  payload: InstallReport;
  accountId: string;
}): Promise<void> => {
  try {
    const res: AxiosResponse<CommonReportResponse> = await AxiosInstance.post(
      `${API_PATH}/create/installation/${accountId}`,
      payload
    );
    if (res.data.error !== 0) throw new Error();
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const putInstallReportCreate = async ({
  payload,
  reportId,
}: {
  payload: InstallReport;
  reportId: string;
}): Promise<void> => {
  try {
    const res: AxiosResponse<CommonReportResponse> = await AxiosInstance.put(
      `${API_PATH}/update/installation/${reportId}`,
      payload
    );
    if (res.data.error !== 0) throw new Error();
  } catch (e) {
    const customErr = e as CustomError;
    throw customErr;
  }
};

export const putCompleteInstallReport = async ({
  reportId,
}: {
  reportId: string;
}): Promise<void> => {
  try {
    const res: AxiosResponse<CommonReportResponse> = await AxiosInstance.put(
      `${API_PATH}/update/installation/complete/${reportId}`
    );
    if (res.data.error !== 0) throw new Error();
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const postSendEmailInstallation = async ({
  reportId,
  accountId,
  payload,
}: {
  reportId: string;
  accountId: string;
  payload: ExportEmailPayLoad;
}): Promise<void> => {
  try {
    const res: AxiosResponse<CommonReportResponse> = await AxiosInstance.post(
      `${API_PATH}/send/installation/email/${accountId}/${reportId}`,
      payload
    );
    if (res.data.error !== 0) throw new Error();
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const getHealthReportList = async ({
  payload,
  reportId,
  accountId,
}: {
  payload: GetReportRequest;
  reportId: string;
  accountId: string;
}): Promise<GetReportHealthList> => {
  try {
    const res: AxiosResponse<GetReportHealthList> = await AxiosInstance.get(
      `${API_PATH}/view/health/${accountId}/${reportId}?${qs.stringify(
        payload
      )}`
    );
    return res.data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const getInventoryMergedList = async ({
  payload,
  reportId,
}: {
  payload: GetReportRequest;
  reportId: string;
}): Promise<GetReportHealthList> => {
  try {
    const res: AxiosResponse<GetReportHealthList> = await AxiosInstance.get(
      `${API_PATH}/view/inventory/${reportId}?${qs.stringify(payload)}`
    );
    return res.data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const getInventoryRecorder = async ({
  payload,
  reportId,
}: {
  payload: GetReportRequest;
  reportId: string;
}): Promise<GetReportInvetoryRecorderList> => {
  try {
    const res: AxiosResponse<GetReportInvetoryRecorderList> =
      await AxiosInstance.get(
        `${API_PATH}/view/inventory/recorder/${reportId}?${qs.stringify(
          payload
        )}`
      );
    return res.data;
  } catch (e) {
    // console.error(e);
    throw new Error();
  }
};

export const getInventoryCamera = async ({
  payload,
  reportId,
}: {
  payload: GetReportRequest;
  reportId: string;
}): Promise<GetReportInventoryCameraList> => {
  try {
    const res: AxiosResponse<GetReportInventoryCameraList> =
      await AxiosInstance.get(
        `${API_PATH}/view/inventory/camera/${reportId}?${qs.stringify(payload)}`
      );
    return res.data;
  } catch (e: any) {
    // console.error(e);
    // const { response } = e;
    // console.log(response);
    if (e.response) {
      return e.response;
    }
    throw new Error();
  }
};

export const getHealthMergedReportList = async ({
  payload,
  reportId,
  mergedSystemId,
  accountId,
}: {
  payload: GetReportRequest;
  reportId: string;
  mergedSystemId: string;
  accountId: string;
}): Promise<GetReportHealthList> => {
  try {
    const res: AxiosResponse<GetReportHealthList> = await AxiosInstance.get(
      `${API_PATH}/view/health/${accountId}/${reportId}/${mergedSystemId}?${qs.stringify(
        payload
      )}`
    );
    return res.data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const getHealthDetailReportList = async ({
  payload,
  accountId,
}: {
  payload: GetReportRequest;
  accountId: string;
}): Promise<GetReportHealthList> => {
  try {
    const res: AxiosResponse<GetReportHealthList> = await AxiosInstance.get(
      `${API_PATH}/view/health/${accountId}?${qs.stringify(payload)}`
    );
    return res.data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const getReportHealthDetailTotal = async ({
  reportId,
  recorderId,
  accountId,
  payload,
}: {
  reportId: string;
  recorderId: string;
  accountId: string;
  payload: GetReportRequest;
}): Promise<GetReportHealthDetail> => {
  try {
    const res: AxiosResponse<GetReportHealthDetail> = await AxiosInstance.get(
      `${API_PATH}/view/health/detail/${accountId}/${reportId}/${recorderId}?${qs.stringify(
        payload
      )}`
    );
    return res.data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const getReportHealthDetailStorage = async ({
  recorderId,
  accountId,
}: {
  recorderId: string;
  accountId: string;
}): Promise<ReportHealthDetailStorageResponse> => {
  try {
    const res: AxiosResponse<ReportHealthDetailStorageResponse> =
      await AxiosInstance.get(
        `${API_PATH}/view/health/detail/${accountId}/${recorderId}/storage`
      );
    return res.data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const getReportInfoTes = async ({
  accountId,
  reportId,
}: {
  accountId: string;
  reportId: string;
}): Promise<getReportInfoResponse> => {
  try {
    const res: AxiosResponse<getReportInfoResponse> = await AxiosInstance.get(
      `${API_PATH}/list/${accountId}/${reportId}`
    );
    return res.data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const getReportInfo = async ({
  accountId,
  reportId,
}: {
  accountId: string;
  reportId: string;
}): Promise<getReportInfoResponse> => {
  try {
    const res: AxiosResponse<getReportInfoResponse> = await AxiosInstance.get(
      `${API_PATH}/list/${accountId}/${reportId}`
    );
    return res.data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const getReportPreferenceInfo = async ({
  accountId,
  reportId,
}: {
  accountId: string;
  reportId: string;
}): Promise<getPreferenceResponse> => {
  try {
    const res: AxiosResponse<getPreferenceResponse> = await AxiosInstance.get(
      `${API_PATH}/preference/${accountId}/${reportId}`
    );
    return res.data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const getReportInfoInstall = async ({
  accountId,
  reportId,
}: {
  accountId: string;
  reportId: string;
}): Promise<getReportInfoResponse> => {
  try {
    const res: AxiosResponse<getReportInfoResponse> = await AxiosInstance.get(
      `${API_PATH}/installation/${accountId}/${reportId}`
    );
    return res.data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const getReportInfoThumbnail = async ({
  accountId,
  reportId,
}: {
  accountId: string;
  reportId: string;
}): Promise<getReportInfoResponse> => {
  try {
    const res: AxiosResponse<getReportInfoResponse> = await AxiosInstance.get(
      `${API_PATH}/thumbnail/${accountId}/${reportId}`
    );
    return res.data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const getReportInfoCVV = async ({
  accountId,
  reportId,
}: {
  accountId: string;
  reportId: string;
}): Promise<getReportInfoResponse> => {
  try {
    const res: AxiosResponse<getReportInfoResponse> = await AxiosInstance.get(
      `${API_PATH}/cvv/${accountId}/${reportId}`
    );
    return res.data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const getReportDetailPerformance = async ({
  reportId,
  recorderId,
  accountId,
  periodicalType,
}: {
  reportId: string;
  recorderId: string;
  accountId: string;
  periodicalType: Interval;
}): Promise<getReoprtDetailPerformanceResponse> => {
  try {
    const res: AxiosResponse<getReoprtDetailPerformanceResponse> =
      await AxiosInstance.get(
        `${API_PATH}/view/health/detail/${accountId}/${reportId}/${recorderId}/performance?periodicalType=${periodicalType}`
      );
    return res.data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const getReportDetailRemoteAccess = async ({
  reportId,
  recorderId,
  accountId,
  periodicalType,
}: {
  reportId: string;
  recorderId: string;
  accountId: string;
  periodicalType: Interval;
}): Promise<getReoprtDetailRemoteAccessResponse> => {
  try {
    const res: AxiosResponse<getReoprtDetailRemoteAccessResponse> =
      await AxiosInstance.get(
        `${API_PATH}/view/health/detail/${accountId}/${reportId}/${recorderId}/remoteAccess?periodicalType=${periodicalType}`
      );
    return res.data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const getReportDetailUser = async ({
  reportId,
  recorderId,
  payload,
}: {
  reportId: string;
  recorderId: string;
  payload: GetReportRequest;
}): Promise<getReoprtDetailUserResponse> => {
  try {
    const res: AxiosResponse<getReoprtDetailUserResponse> =
      await AxiosInstance.get(
        `${API_PATH}/view/health/detail/${reportId}/${recorderId}/user?${qs.stringify(
          payload
        )}`
      );
    return res.data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const getInstallReportView = async ({
  reportId,
  payload,
}: {
  reportId: string;
  payload: BasePagination;
}): Promise<InstallationViewResponse> => {
  try {
    const res: AxiosResponse<InstallationViewResponse> =
      await AxiosInstance.get(
        `${API_PATH}/view/installation/${reportId}?${qs.stringify(payload)}`
      );
    return res.data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const getThumbnailDateTime = async ({
  reportId,
}: {
  reportId: string;
}): Promise<ThumbnailDateTimeResponse> => {
  try {
    const res: AxiosResponse<ThumbnailDateTimeResponse> =
      await AxiosInstance.get(
        `${API_PATH}/view/thumbnail/dateTime/${reportId}`
      );
    return res.data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const getThumbnailView = async ({
  reportId,
  payload,
}: {
  reportId: string;
  payload: ThumbnailQueryString;
}): Promise<ThumbnailViewResponse> => {
  try {
    const res: AxiosResponse<ThumbnailViewResponse> = await AxiosInstance.get(
      `${API_PATH}/view/thumbnail/${reportId}?${qs.stringify(payload)}`
    );
    return res.data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const getThumbnailFilterView = async ({
  reportId,
  payload,
}: {
  reportId: string;
  payload: ThumbnailFilterQueryString;
}): Promise<ThumbnailViewFilterResponse> => {
  try {
    const res: AxiosResponse<ThumbnailViewFilterResponse> =
      await AxiosInstance.get(
        `${API_PATH}/view/thumbnail/filter/${reportId}?${qs.stringify(payload)}`
      );
    return res.data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const getThumbnailRecorderList = async ({
  accountId,
  reportId,
}: {
  accountId: string;
  reportId: string;
}): Promise<ThumbnailRecorderResponse> => {
  try {
    const res: AxiosResponse<ThumbnailRecorderResponse> =
      await AxiosInstance.get(
        `${API_PATH}/thumbnail/filter/recorder/${accountId}/${reportId}`
      );
    return res.data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const getThumbnailCameraList = async ({
  accountId,
  reportId,
  recorderId,
}: {
  accountId: string;
  reportId: string;
  recorderId: string;
}): Promise<ThumbnailCameraResponse> => {
  try {
    const res: AxiosResponse<ThumbnailCameraResponse> = await AxiosInstance.get(
      `${API_PATH}/thumbnail/filter/camera/${accountId}/${reportId}/${recorderId}`
    );
    return res.data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const getThumbnailDateList = async ({
  accountId,
  reportId,
  recorderId,
  cameraId,
}: {
  accountId: string;
  reportId: string;
  recorderId: string;
  cameraId: string;
}): Promise<ThumbnailFilterDateResponse> => {
  try {
    const res: AxiosResponse<ThumbnailFilterDateResponse> =
      await AxiosInstance.get(
        `${API_PATH}/thumbnail/filter/camera/${accountId}/${reportId}/${recorderId}/${cameraId}`
      );
    return res.data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const getCvvView = async ({
  reportId,
  payload,
}: {
  reportId: string;
  payload: CvvQueryString;
}): Promise<CvvViewResponse> => {
  try {
    const res: AxiosResponse<CvvViewResponse> = await AxiosInstance.get(
      `${API_PATH}/view/cvv/${reportId}?${qs.stringify(payload)}`
    );
    return res.data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const getCvvRecorderList = async ({
  accountId,
  reportId,
}: {
  accountId: string;
  reportId: string;
}): Promise<CvvRecorderResponse> => {
  try {
    const res: AxiosResponse<CvvRecorderResponse> = await AxiosInstance.get(
      `${API_PATH}/cvv/filter/recorder/${accountId}/${reportId}`
    );
    return res.data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const putCVVReferenceUpdate = async ({
  reportId,
  payload,
}: {
  reportId: string;
  payload: CVVReferenceUpdateReq;
}): Promise<CommonReportResponse> => {
  try {
    const res: AxiosResponse<CommonReportResponse> = await AxiosInstance.put(
      `${API_PATH}/update/reference/${reportId}`,
      payload
    );
    return res.data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const putCVVReferenceUpdateAll = async ({
  reportId,
  recorderId,
}: {
  reportId: string;
  recorderId: string;
}): Promise<CommonReportResponse> => {
  try {
    const res: AxiosResponse<CommonReportResponse> = await AxiosInstance.put(
      `${API_PATH}/update/references/${reportId}/${recorderId}`
    );
    return res.data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const getSendEmailReport = async (
  accountId: string,
  reportId: string
): Promise<SendEmailReportResponse> => {
  try {
    const { data }: AxiosResponse<SendEmailReportResponse> =
      await AxiosInstance.get(`${API_PATH}/sendEmail/${accountId}/${reportId}`);
    return data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const sendExportEmail = async ({
  accountId,
  payload,
}: {
  accountId: string;
  payload: ExportEmailPayLoad;
}): Promise<CommonReportResponse> => {
  try {
    const res: AxiosResponse<CommonReportResponse> = await AxiosInstance.post(
      `${API_PATH}/sendEmail/${accountId}`,
      payload
    );
    return res.data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const getInstallSync = async ({
  reportId,
}: {
  reportId: string;
}): Promise<CommonResponse> => {
  try {
    const res: AxiosResponse<CommonResponse> = await AxiosInstance.get(
      `${API_PATH}/installation/sync/${reportId}`
    );
    return res.data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const getInstallThumbnailSync = async ({
  reportId,
}: {
  reportId: string;
}): Promise<CommonResponse> => {
  try {
    const res: AxiosResponse<CommonResponse> = await AxiosInstance.post(
      `${API_PATH}/update/installation/thumbnails/${reportId}`
    );
    return res.data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const getSpectrumStatus = async ({
  recorderId,
}: {
  recorderId: string;
}): Promise<SpectrumSystemStatusRes> => {
  try {
    const res: AxiosResponse<SpectrumSystemStatusRes> = await AxiosInstance.get(
      `${API_PATH}/health/systemSpecs/${recorderId}`
    );
    return res.data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const getSpectrumStats = async ({
  recorderId,
}: {
  recorderId: string;
}): Promise<SpectrumSystemStatsRes> => {
  try {
    const res: AxiosResponse<SpectrumSystemStatsRes> = await AxiosInstance.get(
      `${API_PATH}/health/systemStats/${recorderId}`
    );
    return res.data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const getSystemUsage = async ({
  recorderId,
}: {
  recorderId: string;
}): Promise<SystemUsageRes> => {
  try {
    const res: AxiosResponse<SystemUsageRes> = await AxiosInstance.get(
      `${API_PATH}/health/metrics/${recorderId}`
    );
    return res.data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const getVmaxStatus = async ({
  recorderId,
}: {
  recorderId: string;
}): Promise<VmaxSystemStatusRes> => {
  try {
    const res: AxiosResponse<VmaxSystemStatusRes> = await AxiosInstance.get(
      `${API_PATH}/health/systemSpecs/${recorderId}`
    );
    return res.data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};
