import styled from "styled-components";
import { COLORS } from "styles/colors";
import { FLEX } from "styles/flex";
import * as mediaQuery from "components/MediaQuery";
import { calcRem } from "styles/theme";
import { motion } from "framer-motion";
import { UnderlineText } from "components/atoms/text/Text";
import { noScrollBar } from "styles/commonStyles";

export const Container = styled.header`
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 89;
  background-color: transparent;
  ${FLEX.FlexCenterCenter};

  ${mediaQuery.isDefault} {
    height: 50px;
  }
  ${mediaQuery.isMobile} {
    flex-direction: column;
  }
`;

export const Inner = styled.div`
  width: 100%;
  ${FLEX.FlexBetweenCenter};
  padding: 0 10px;
`;

export const Left = styled.div`
  ${FLEX.FlexStartCenter};
  gap: 4px;

  > button {
    color: ${({ theme }) => theme.COLORS.WHITE};
    font-weight: bold;
    font-size: 14px;
    padding: 8px;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.COLORS.FILTERITEM};
    ${FLEX.FlexCenterCenter};
    gap: 4px;
  }
`;

export const FilterLeft = styled(motion.div)`
  ${FLEX.FlexStartCenter};
  gap: 4px;
`;

export const Right = styled(Left)`
  justify-content: flex-end;
`;

export const SelectFilter = styled.div<{
  isOpen?: boolean;
  isFilter?: boolean;
}>`
  padding: 8px 10px;
  border: 1px solid #abb5c5;
  /* color: black; */
  color: ${({ theme }) => theme.COLORS.WHITE};
  font-size: ${calcRem(14)};
  ${FLEX.FlexCenterCenter};
  gap: 24px;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
  position: relative;
  white-space: nowrap;
  background-color: ${({ theme }) =>
    theme.value === "light" ? COLORS.WHITE : theme.COLORS.FILTERITEM};

  > svg,
  > button {
    ${({ isOpen }) =>
      isOpen &&
      `
        transform: scaleY(-1);
        transition: 0.3s;
      `}
    color: ${({ theme }) => theme.COLORS.WHITE};
  }

  ${({ isFilter, theme }) =>
    isFilter &&
    `
    // border-color: ${theme.COLORS.SELECTED};
    background-color: ${theme.COLORS.SELECTED};
    font-weight: 500;
    color: ${COLORS.WHITE};

    * {
      color: ${COLORS.WHITE};
    }
  `}

  button {
    ${FLEX.FlexCenterCenter};
  }

  ${mediaQuery.isMobile} {
    gap: 8px;
  }
`;

export const CollapseButton = styled.button<{ isCollapsed: boolean }>`
  border-radius: 50% !important;
  width: 30px;
  height: 30px;

  > img {
    width: 95%;
    height: 95%;
    ${({ isCollapsed }) =>
      isCollapsed
        ? `
        transform: rotate( 180deg );
        `
        : `
        transform: rotate( 360deg );
      `}
    transition: all 0.3s linear;
  }
  ${({ theme }) =>
    theme.value === "light" &&
    `
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  `}
`;

export const SearchInMap = styled.label`
  padding: 8px 10px;
  background-color: ${({ theme }) => theme.COLORS.FILTERITEM};
  border-radius: 16px;
  color: ${({ theme }) => theme.COLORS.WHITE};
  font-size: ${calcRem(13)};
  gap: 5px;
  white-space: nowrap;

  > input[type="checkbox"] {
    width: 14px;
    height: 14px;
  }

  ${({ theme }) =>
    theme.value === "light" &&
    `
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  `}
`;

export const ClearFilterButton = styled(SelectFilter)`
  text-shadow: 1px 1px 1px ${COLORS.BLACK}60;
  color: ${({ theme }) => theme.COLORS.WHITE};
`;

export const MapSearchWrapper = styled.div`
  width: 100%;
  margin-top: 4px;
  ${FLEX.FlexCenterCenter};

  > div {
    width: 95%;
  }

  label {
    width: 100%;
    max-width: 100%;
    /* height: 100%; */
  }
`;

export const MobileMapInner = styled.div`
  width: 100%;
  /* height: 40px; */
  background-color: ${({ theme }) => theme.COLORS.PRIMARY2};
  padding: 6px 15px;
  position: relative;

  > button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 15px;

    > img {
      height: 100%;
    }
  }

  .button-left {
    left: 3px;
  }
  .button-right {
    right: 3px;
  }
`;

export const MobileMapFilter = styled.div`
  width: 100%;
  ${FLEX.FlexStartCenter};
  gap: 4px;
  overflow-x: auto;

  ${noScrollBar};
`;
