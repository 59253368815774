import { createSearchParams, Outlet } from "react-router-dom";
import { TableTemplate } from "components/templates/default/table/TableTemplate";
import Status from "components/blocks/status/Status";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { AlertViewInfo } from "api/interfaces/alertInterface.interface";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { useAppSelector } from "redux/hooks";
import { useQuery, useQueryClient } from "react-query";
import {
  BasePagination,
  SORT_DIRECTION,
} from "api/interfaces/commonInterface.interface";
import { isNull } from "lodash";
import { menuNavigation, queryNavigate } from "utils/MenuUtil";
import { getRecorder } from "api/recorderAPI";

export default function AlertHistoryDetailPage(): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const isAlertView = location.pathname.includes("view");

  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );
  const queryClient = useQueryClient();

  const [urlSearchParams] = useSearchParams();

  const recorderId = urlSearchParams.get("recorderId");

  const [mergedCount, setMergedCount] = useState<number>(0);

  const [selectedAlert, setSelectedAlert] = useState<AlertViewInfo>({
    type: location.state ? location.state.type : "",
    recorderId: recorderId ?? "",
    recorderName: location.state ? location.state.recorderName : "",
    mergedSystemName: location.state ? location.state.systemName : "",
    mergedSystemId: location.state ? location.state.mergedSystemId : "",
    // alertType: location.state ? location.state.alertType : "",
  });

  const [isPending, setIsPending] = useState<boolean>(true);

  useEffect(() => {
    if (urlSearchParams.size > 0) {
      setAlertHistoryDetailQueryInfo((info) => {
        return {
          ...info,
          pageNum: isNull(urlSearchParams.get("pageNum"))
            ? 0
            : Number(urlSearchParams.get("pageNum")),
          pageLimit: isNull(urlSearchParams.get("pageLimit"))
            ? 20
            : Number(urlSearchParams.get("pageLimit")),
          keyword: isNull(urlSearchParams.get("keyword"))
            ? ""
            : (urlSearchParams.get("keyword") as string),
          sortType: isNull(urlSearchParams.get("sortType"))
            ? "alertTrigger"
            : (urlSearchParams.get("sortType") as string),
          sortDirection: isNull(urlSearchParams.get("sortDirection"))
            ? SORT_DIRECTION.DESC
            : (urlSearchParams.get("sortDirection") as SORT_DIRECTION),
          category: isNull(urlSearchParams.get("category"))
            ? null
            : (urlSearchParams.get("category") as string),
          status: isNull(urlSearchParams.get("status"))
            ? null
            : (urlSearchParams.get("status") as string),
        };
      });
    } else {
      setAlertHistoryDetailQueryInfo((info) => {
        return {
          ...info,
          pageNum: 0,
          pageLimit: 20,
          keyword: "",
          sortType: "alertTrigger",
          sortDirection: SORT_DIRECTION.DESC,
          category: null,
          status: null,
        };
      });
    }
  }, [location]);

  const titleComponent =
    mergedCount !== undefined && mergedCount > 1
      ? [
          {
            label: "Alert History",
            onClick: () =>
              menuNavigation(selectedAccount, navigate, "/alert/history"),
            //navigate("/alert/history"),
          },
          {
            label: selectedAlert.mergedSystemName,
            onClick: () =>
              menuNavigation(
                selectedAccount,
                navigate,
                `/alert/history/merged/${selectedAlert.mergedSystemId}`
              ),
          },
          {
            label: selectedAlert.recorderName,
            onClick: () => {
              if (isAlertView && recorderId) {
                navigate({
                  pathname: `/alert/history/detail`,
                  search: `?${createSearchParams({
                    recorderId: recorderId,
                    account: selectedAccount.accountId,
                  })}`,
                });
              }
            },
          },
        ]
      : [
          {
            label: "Alert History",
            onClick: () =>
              menuNavigation(selectedAccount, navigate, "/alert/history"),
          },
          {
            label: selectedAlert.recorderName,
            onClick: () => {
              if (isAlertView && recorderId) {
                navigate({
                  pathname: `/alert/history/detail`,
                  search: `?${createSearchParams({
                    recorderId: recorderId,
                    account: selectedAccount.accountId,
                  })}`,
                });
              }
            },
          },
        ];
  const [listTotal, setListTotal] = useState<number>(0);

  const detailStatus = useMemo(() => {
    return {
      totalTitle: "Total Alerts",
      totalValue: listTotal,
    };
  }, [listTotal]);

  const [alertHistoryDetailQueryInfo, setAlertHistoryDetailQueryInfo] =
    useState<BasePagination>({
      total: 0,
      pageNum: 0,
      pageLimit: 20,
      keyword: "",
      sortType: "alertTrigger",
      sortDirection: SORT_DIRECTION.DESC,
      category: null,
      status: null,
    });

  const detailCenterChildren = useMemo(() => {
    return <Status status={detailStatus} />;
  }, [detailStatus]);

  const recorderInfo = useQuery(
    ["recorder", selectedAccount.accountId],
    () =>
      getRecorder({
        accountId: selectedAccount.accountId,
        recorderId: recorderId as string,
      }),

    {
      retry: 0,
      refetchOnWindowFocus: false,
      enabled: selectedAccount.accountId !== "",
      onSuccess: (res: any) => {
        if (res.result === undefined || res.error !== 0) {
          return;
        }
        setMergedCount(res.result.mergedCount as number);
        setSelectedAlert((info) => {
          return {
            ...info,
            type: res.result.type,
            recorderId: res.result.recorderId,
            recorderName: res.result.name,
            mergedSystemName: res.result.mergedSystemName,
            mergedSystemId: res.result.mergedSystemId,
          };
        });
      },
      onError: (e: any) => {
        // notify("error", intl.formatMessage({
        //   id: "label.recorder.notify.search.error",
        //   defaultMessage: "Registered Recorder not founded.",
        // }));
      },
      onSettled: (e: any) => {
        setIsPending(false);
      },
    }
  );

  const onClickSearch = useCallback(
    (keyword: string) => {
      if (alertHistoryDetailQueryInfo.keyword !== keyword) {
        queryNavigate(navigate, location.pathname, {
          pageLimit: alertHistoryDetailQueryInfo.pageLimit,
          pageNum: 0,
          keyword: keyword,
          sortType: alertHistoryDetailQueryInfo.sortType,
          sortDirection:
            alertHistoryDetailQueryInfo.sortDirection as SORT_DIRECTION,
          category: alertHistoryDetailQueryInfo.category,
          status: alertHistoryDetailQueryInfo.status,
        });
      } else {
        queryClient.invalidateQueries("alertHistoryDetail");
      }
      setIsPending(true);
    },
    [
      alertHistoryDetailQueryInfo.category,
      alertHistoryDetailQueryInfo.keyword,
      alertHistoryDetailQueryInfo.pageLimit,
      alertHistoryDetailQueryInfo.sortDirection,
      alertHistoryDetailQueryInfo.sortType,
      alertHistoryDetailQueryInfo.status,
      location.pathname,
      navigate,
    ]
  );

  const outletContext = {
    alertHistoryDetailQueryInfo,
    setAlertHistoryDetailQueryInfo,
    listTotal,
    setListTotal,
    isPending,
    setIsPending,
  };

  return (
    <TableTemplate
      detail
      titleComponent={titleComponent}
      centerChildren={!isAlertView && detailCenterChildren}
      onClickSearch={onClickSearch}
      keyword={alertHistoryDetailQueryInfo.keyword}
      noSearch={isAlertView}
    >
      <Outlet context={outletContext} />
    </TableTemplate>
  );
}
