import {
  useOutlet,
  useLocation,
  useParams,
  useSearchParams,
  useNavigate,
} from "react-router-dom";
import { useEffect, useLayoutEffect, useRef, useState } from "react";

import SideNavBar from "components/blocks/sidenav/SideNavBar";
import HeaderNavBar from "components/blocks/headernav/HeaderNavBar";
import InnerContainer from "components/atoms/inner/InnerContainer";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import type {
  MenuProps,
  RoutesProps,
} from "api/interfaces/menuInterface.interface";
import * as s from "../default/DefaultTemplateStyled";
import * as mediaQuery from "components/MediaQuery";
import { onOpen } from "redux/reducers/sidenav/SideNavReducer";
import UserPopup from "components/blocks/headernav/sub/UserPopup";
import { setOffPopup } from "redux/reducers/menu/userPopup";
import { NoticeState } from "redux/reducers/notices/noticeReducer";
import { useProSidebar } from "react-pro-sidebar";
import SwitchAccount from "pages/account/switch/SwitchAccount";
import { checkMenuRole, getSubMenu } from "utils/MenuUtil";
import { setMenu } from "redux/reducers/menu/menuSlice";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { checkPermission, getAccountInfo } from "utils/AccountUtil";
import { setAccountSetting } from "redux/reducers/account/accountSlice";
import { setBreadcrumbRoute } from "redux/reducers/breadcrumb/breadcrumb";
import { AccountId } from "components/blocks/headernav/sub/SubStyled";

type Props = {
  onMenuClick?: (index: number) => void;
};

export const MainLayout = ({ onMenuClick }: Props) => {
  const dispatch = useAppDispatch();
  const outlet = useOutlet();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  // menu collapse 상태
  const { collapsed } = useProSidebar();
  const isOpen: boolean = useAppSelector((state) => state.sidenavOpen.isOpen);
  const isPopup: boolean = useAppSelector((state) => state.userPopup);
  const notice: NoticeState = useAppSelector((state) => state.notice);

  const buttonRef = useRef<any>(null);

  const selectMenuProps: Array<RoutesProps> = useAppSelector(
    (state) => state.menuSelect.menuData
  );

  const onChangeOpen = () => {
    dispatch(onOpen(!isOpen));
    // setIsOpen(!isOpen);
  };

  // 지도 화면일 때 꽉찬 화면으로 나오도록 확인하는 변수
  const isMap = location.pathname.includes("/dashboard/map");
  // console.log(location.pathname);
  // 지도 화면일 때 꽉찬 화면으로 나오도록 확인하는 변수
  const isHelp = location.pathname.includes("/help");
  const isAdminandMap =
    isMap ||
    // isHelp ||
    location.pathname.includes("/dashboard/admin");

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSwitchAccount, setIsSwitchAccount] = useState<boolean>(false);
  const onChangeSwitch = () => {
    setIsSwitchAccount(!isSwitchAccount);
  };
  useLayoutEffect(() => {}, [notice]);

  useLayoutEffect(() => {
    setIsLoading(false);
  }, []);

  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );
  const params = useParams();

  useEffect(() => {
    const findSubMenu = getSubMenu(location.pathname);
    if (findSubMenu !== undefined) {
      dispatch(setMenu(findSubMenu.name));
    }

    const accountUrl = searchParams.get("account") as string;
    // if (
    //   selectedAccount.accountId !== accountUrl
    // ) {
    getAccountInfo(accountUrl !== null ? accountUrl : selectedAccount.accountId)
      .then(function (selectAccount) {
        if (!!selectAccount) {
          if (
            checkMenuRole(
              findSubMenu?.routeName as string,
              location.pathname,
              selectAccount
            )
          ) {
            dispatch(setAccountSetting(selectAccount));
          } else {
            navigate("/error/404");
          }
        }

        if (
          selectAccount.parentAccount !== undefined &&
          selectAccount.parentAccount.length > 0
        ) {
          const bcArray = [...selectAccount.parentAccount];
          const setBreadCrumb = bcArray.reverse().map((link) => {
            return {
              name: link.accountName,
              routeName: "/account",
              accountId: link.accountId,
            };
          });
          setBreadCrumb.push({
            name: selectAccount.accountName,
            routeName: "/account",
            accountId: selectAccount.accountId,
          });
          dispatch(setBreadcrumbRoute(setBreadCrumb));
        } else {
          dispatch(
            setBreadcrumbRoute([
              {
                name: selectAccount.accountName,
                routeName: "/account",
                accountId: selectAccount.accountId,
              },
            ])
          );
        }
      })
      .catch(() => {
        navigate("/error/403");
      });
    //  }
  }, [location]);

  useEffect(() => {
    const onClick = (event: any) => {
      if (isPopup) {
        dispatch(setOffPopup());
      }
    }
    document.body.addEventListener("click", onClick);
    return function cleanup() {
      window.removeEventListener("click", onClick);
    };
  }, [isPopup]);

  const isVideoShare = location.pathname === "/videoShare";
  const isDashboard = location.pathname.includes("/dashboard");

  return (
    <>
      <mediaQuery.Mobile>
        <s.Container noticeLength={notice.notices.length}>
          <s.ContentsContainer
            isVideoShare={false}
            isOpen={false}
            isDashboard={isDashboard}
          >
            {/* {menuData && <RoutesMark headerRoutes={selectMenuProps} />} */}
            {isSwitchAccount && (
              <SwitchAccount onChangeSwitch={onChangeSwitch} />
            )}
            <HeaderNavBar
              menu={selectMenuProps}
              onOpen={onChangeOpen}
              onMenuClick={onMenuClick}
              onChangeSwitch={onChangeSwitch}
              buttonRef={buttonRef}
            />
            <InnerContainer
              map={location.pathname.includes("/dashboard/admin")}
              help={
                isHelp ||
                isMap ||
                location.pathname.includes("/dashboard/chart")
              }
            >
              {outlet}
            </InnerContainer>
          </s.ContentsContainer>
          {isOpen && <SideNavBar onChangeSwitch={onChangeSwitch} />}
        </s.Container>
      </mediaQuery.Mobile>
      <mediaQuery.Default>
        {!isLoading && (
          <s.Layout noticeLength={notice.notices.length}>
            <SideNavBar onChangeSwitch={() => null} />
            {isSwitchAccount && (
              <SwitchAccount onChangeSwitch={onChangeSwitch} />
            )}

            <s.ContentsContainer
              isVideoShare={isVideoShare}
              isOpen={collapsed}
              isDashboard={isDashboard}
            >
              <HeaderNavBar
                menu={selectMenuProps}
                onMenuClick={onMenuClick}
                onChangeSwitch={onChangeSwitch}
                buttonRef={buttonRef}
              />

              <InnerContainer
                map={isAdminandMap || isHelp}
                id="inner-container"
              >
                {outlet}
              </InnerContainer>
            </s.ContentsContainer>
          </s.Layout>
        )}
      </mediaQuery.Default>
    </>
  );
};
