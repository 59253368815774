import { TableTemplate } from "components/templates/default/table/TableTemplate";
import { Text } from "components/atoms/text/Text";
import { COLORS } from "styles/colors";
import * as s from "./CvvStyled";
import BigButton from "components/atoms/buttons/BigButton";
import SearchInput, {
  ClickSearch,
} from "components/atoms/input/search/SearchInput";
import { CheckInput } from "styles/ModalCommonStyled";
import { Option } from "utils/options";
import CvvCamera from "./CvvCamera";
import CvvLabel from "./CvvLabel";
import PaginationComponent from "components/blocks/pagination";
import CheckInclude from "components/blocks/subheader/sub/CheckInclude";
import StorageLabel, {
  StorageProps,
} from "components/blocks/storage/StorageLabel";
import { useNavigate } from "react-router-dom";
import { StorageWrapper } from "components/templates/default/table/TableTemplateStyled";
import * as mediaQuery from "components/MediaQuery";
import {
  CVVPairItem,
  CvvQueryString,
  CvvViewListItem,
  DayNightItem,
} from "api/interfaces/reportInterface.interface";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { checkCVVError } from "utils/functions";
import { isEmpty } from "lodash";
import NoData from "components/templates/nopage/NoData";
import { menuNavigation } from "utils/MenuUtil";
import PaginationNoTableComponent from "components/blocks/pagination/PaginationNoTable";
import RBACWrapper from "components/blocks/function/RBACWrapper";
import Selects from "components/atoms/select/Select";

type Props = {
  title: string | undefined;
  storageUsage: StorageProps;
  recorderList: Option[];
  cvvList: CvvViewListItem;
  selectFilterRecorder: string;
  onChangeFilterRecorder: (value: string) => void;
  onClickSearch: ClickSearch;
  cvvQueryInfo: CvvQueryString;
  onChangePage: (page: number, totalRows: number) => void;
  selectedAccount: ProfileAccountInfo;
  onChangeIsCvv: (checked: boolean) => void;
  onClickUpdate: (data: DayNightItem, pairItem: CVVPairItem) => void;
  onClickUpdateAll: () => void;
  currentCvv: DayNightItem | null;
  currentPairitem: CVVPairItem | null;
  listCount: number;
  // setIsCvvOpen: any;
};

export default function CvvPresenter(props: Props): JSX.Element {
  const navigate = useNavigate();
  const infoHeaders = [
    {
      title: "Cameras",
      content: (
        <Text bold fontSize={18}>
          {props.cvvList.usageCameras}/{props.cvvList.totalCameras} CH
        </Text>
      ),
    },
    {
      title: "CVV",
      content: (
        <Text bold fontSize={18}>
          <strong>
            Alert:{" "}
            {props.cvvList.warningCameras !== undefined
              ? props.cvvList.warningCameras
              : 0}
          </strong>{" "}
          / {props.cvvList.usageCameras}
        </Text>
      ),
    },
    {
      title: "Location",
      content: (
        <Text bold fontSize={18}>
          {props.cvvList.location.location
            ? props.cvvList.location.location
            : "-"}
        </Text>
      ),
    },
  ];

  const sub = (
    <StorageWrapper>
      <StorageLabel
        totalStorage={props.storageUsage.totalStorage}
        usedStorage={props.storageUsage.usedStorage}
      />
    </StorageWrapper>
  );

  const titleComponent = [
    {
      label: "Report List",
      onClick: () =>
        menuNavigation(props.selectedAccount, navigate, "/reports"),
    },
    {
      label: props.title,
    },
  ];
  const cvvEvent = ["blur", "dust", "glare", "spider", "tampered"];

  let isError = false;
  if (props.currentPairitem !== undefined && props.currentPairitem !== null) {
    isError = checkCVVError(props.currentPairitem.eventThumbnail);
  }
  return (
    <>
      <TableTemplate
        detail
        noSearch
        buttons={sub}
        titleComponent={titleComponent}
      >
        <mediaQuery.Mobile>
          <s.HeaderRight>
            <div className="select-wrapper">
              <Selects
                options={props.recorderList}
                value={
                  props.selectFilterRecorder !== undefined &&
                  props.selectFilterRecorder !== ""
                    ? props.recorderList.find(
                        (option) => option.value === props.selectFilterRecorder
                      )
                    : props.recorderList[0]
                }
                onChange={props.onChangeFilterRecorder}
              />
            </div>
            <div className="search-input">
              <SearchInput
                placeholder="Search"
                onClickSearch={props.onClickSearch}
              />
            </div>
          </s.HeaderRight>
        </mediaQuery.Mobile>
        <s.Header>
          <s.HeaderInfo>
            <CheckInput>
              {props.cvvList.isWarning !== undefined &&
                props.cvvList.isWarning && (
                  <CvvLabel
                    status={props.cvvList.isWarning ? "warning" : "normal"}
                  />
                )}

              <Text fontSize={18} bold>
                {props.cvvList.date !== undefined ? props.cvvList.date : ""}
              </Text>
            </CheckInput>
            <CheckInclude
              label="Camera View Verification"
              onChange={props.onChangeIsCvv}
              isCVV
            />
          </s.HeaderInfo>
          <mediaQuery.Default>
            <s.HeaderRight>
              <div className="select-wrapper">
                <Selects
                  options={props.recorderList}
                  value={
                    props.selectFilterRecorder !== undefined &&
                    props.selectFilterRecorder !== ""
                      ? props.recorderList.find(
                          (option) =>
                            option.value === props.selectFilterRecorder
                        )
                      : props.recorderList[0]
                  }
                  onChange={props.onChangeFilterRecorder}
                />
              </div>
              <div className="search-input">
                <SearchInput
                  placeholder="Search"
                  onClickSearch={props.onClickSearch}
                />
              </div>
            </s.HeaderRight>
          </mediaQuery.Default>
        </s.Header>
        <s.Container>
          {!isEmpty(props.cvvList.cameras) ? (
            <>
              <s.ContentHeader>
                <s.ContentInfo>
                  <mediaQuery.Default>
                    {infoHeaders.map((item, idx) => (
                      <s.ContentInfoItem key={item.title}>
                        <Text className="content-info-item-title">
                          {item.title}
                        </Text>
                        {item.content}
                      </s.ContentInfoItem>
                    ))}
                  </mediaQuery.Default>
                  <mediaQuery.Mobile>
                    {infoHeaders.map(
                      (item, idx) =>
                        idx !== 2 && (
                          <s.ContentInfoItem key={item.title}>
                            <Text color={`${COLORS.WHITE}50`}>
                              {item.title}
                            </Text>
                            {item.content}
                          </s.ContentInfoItem>
                        )
                    )}
                  </mediaQuery.Mobile>
                </s.ContentInfo>
                <mediaQuery.Mobile>
                  <s.ContentInfoItem>
                    <Text color={`${COLORS.WHITE}50`}>
                      {infoHeaders[2].title}
                    </Text>
                    {infoHeaders[2].content}
                  </s.ContentInfoItem>
                </mediaQuery.Mobile>
                <p>
                  <RBACWrapper requiredPermissions="manageReportRules">
                    <BigButton
                      buttonType="cancel"
                      label="Update Cameras"
                      onClickBtn={() => props.onClickUpdateAll()}
                    />
                  </RBACWrapper>
                </p>
              </s.ContentHeader>
              <ul>
                {props.cvvList.cameras.map((data) => (
                  <CvvCamera
                    key={data.id}
                    recorderType ={props.cvvList.recorderType}
                    timezone={props.cvvList.timezone}
                    selectedAccount={props.selectedAccount}
                    data={data}
                    onClickUpdate={props.onClickUpdate}
                    selectFilterRecorder={props.selectFilterRecorder}
                    recorderList={props.recorderList}
                  />
                ))}
              </ul>
            </>
          ) : (
            <NoData />
          )}
        </s.Container>

        <PaginationNoTableComponent
          rowsPerPage={props.cvvQueryInfo.pageLimit}
          rowCount={props.listCount}
          currentPage={props.cvvQueryInfo.pageNum}
          onChangePage={props.onChangePage}
          onChangeRowsPerPage={() => {}}
        />
      </TableTemplate>
      {/* {props.isCvvOpen &&
        props.currentCvv !== null &&
        props.currentPairitem !== null && (
          <BigPopupNoHeaderTemplate>
            <s.ModalHeader>
              <s.ModalInfo>
                <s.CameraHeaderItem>
                  <Text color={`${COLORS.WHITE}50`}>Recorders</Text>
                  <Text bold>
                    {props.selectFilterRecorder !== undefined &&
                    props.selectFilterRecorder !== ""
                      ? props.recorderList.find(
                          (option) =>
                            option.value === props.selectFilterRecorder
                        )?.label
                      : ""}
                  </Text>
                </s.CameraHeaderItem>

                <s.CameraHeaderItem>
                  <Text color={`${COLORS.WHITE}50`}>Camera</Text>
                  <Text
                    bold
                  >{`${props.currentCvv.name} #${props.currentCvv.channel}`}</Text>
                </s.CameraHeaderItem>

                <s.CameraHeaderItem>
                  <Text color={`${COLORS.WHITE}50`}>Date / Time</Text>
                  <Text bold>
                    {getRecorderTimeFormat(
                      props.currentPairitem.eventThumbnail.date,
                      props.selectedAccount,
                      props.cvvList.timezone,
                      true,
                      true
                    )}
                  </Text>
                </s.CameraHeaderItem>
              </s.ModalInfo>

              <button onClick={() => props.setIsCvvOpen(false)}>
                <img alt="close button" src={CLOSE_ICON} />
              </button>
            </s.ModalHeader>
            <s.CvvContainer isError={isError}>
              {isError && (
                <s.CvvHeader>
                  <img src={WARNIN_CIRCLE_ICON} alt="warning circle icon" />
                  CVV has detected obstructions blocking the camera's view. For
                  optimal viewing quality, please remove them as soon as
                  possible.
                </s.CvvHeader>
              )}

              <s.CvvThumbnailWrapper>
                <s.ErrorWrapper>
                  {cvvEvent.map((evt, index) => {
                    if (
                      props.currentCvv !== null &&
                      props.currentCvv.day.eventThumbnail[evt] === true
                    ) {
                      return <SmallLabel label={evt} color={COLORS.RED} />;
                    } else return null;
                  })}
                </s.ErrorWrapper>
                <CVVThumbnail
                  videoLoss={false}
                  thumbnailUrl={`${
                    props.currentPairitem.eventThumbnail.url
                  }?v=${Date.now()}`}
                />
              </s.CvvThumbnailWrapper>
            </s.CvvContainer>
          </BigPopupNoHeaderTemplate>
        )} */}
    </>
  );
}
