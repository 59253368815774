import * as s from "../TableItemStyled";
import { useCallback, useEffect, useState } from "react";
import { useQuery } from "react-query";
import {
  BasePagination,
  LEVEL_TYPE,
  SORT_DIRECTION,
} from "api/interfaces/commonInterface.interface";
import { useIntl } from "react-intl";
import {
  ActiveAlert,
  AlertViewInfo,
} from "api/interfaces/alertInterface.interface";

import { displayAlert } from "utils/AlertUtil";
import { getRecorderTimeFormat } from "utils/timeUtil";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { useAppSelector } from "redux/hooks";
import { UnderlineText } from "components/atoms/text/Text";
import {
  commonOrgColumn,
  commonPaginationPerPage,
} from "pages/dashboard/dashboard/WidgetContainer";
import SearchInput from "components/atoms/input/search/SearchInput";
import ModalSubMenu from "components/blocks/modal/menu/ModalSubMenu";
import { postWidgetListAlert } from "api/widgetAPI";
import { PostWidgetAlertListResponse } from "api/interfaces/widgetInterface.interface";
import AccountSelects from "../AccountSelects";
import {
  DashboardChartDetailTable,
  changeSortOrderString,
} from "components/atoms/table/AntdTable";
import { TableColumnsType } from "antd";
import { SortOrder, SorterResult } from "antd/es/table/interface";
import { Layout } from "react-grid-layout";
import { TableItemName } from "../TableItemList";
import TableItemHeader from "../TableItemHeader";
import RecorderTitle from "components/blocks/recorder/RecorderTitle";
import AlertViewModal from "pages/alert/modal/view";

interface Props {
  layout: Layout;
  chartLayout: Layout[];
  onDeleteWidget: (key: TableItemName) => void;
  onLayoutChange: (layout: Layout[]) => void;
}

export default function AlertList(props: Props): JSX.Element {
  const intl = useIntl();
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );

  const tabs = [{ label: "Video" }, { label: "System" }, { label: "Disk" }];

  const [currentMenu, setCurrentMenu] = useState(0);
  const [accountIds, setAccountIds] = useState<string[]>([]);

  const [isViewModal, setIsViewModal] = useState(false);

  const [data, setData] = useState<ActiveAlert[]>([]);
  const [selectedAlert, setSelectedAlert] = useState<
    AlertViewInfo | undefined
  >();

  const initialQueryInfo = {
    total: 0,
    pageNum: 0,
    pageLimit: commonPaginationPerPage,
    keyword: "",
    sortType: "alert_trigger",
    sortDirection: SORT_DIRECTION.DESC,
  };

  const [alertActiveQueryInfo, setAlertActiveQueryInfo] =
    useState<BasePagination>({
      ...initialQueryInfo,
    });

  const { error, refetch, isError } = useQuery(
    [
      "postWidgetListAlert",
      accountIds,
      currentMenu,
      alertActiveQueryInfo,
      selectedAccount,
    ],
    () =>
      postWidgetListAlert({
        accountId: selectedAccount.accountId,
        payload: {
          accountIds,
          type: tabs[currentMenu].label,
          listDto: alertActiveQueryInfo,
        },
      }),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      onSuccess: (res: PostWidgetAlertListResponse) => {
        if (res.error !== 0 && res.result === undefined) {
          setData([]);
          setAlertActiveQueryInfo((info) => {
            return { ...info, total: 0 as number, pageNum: 0 };
          });
        } else {
          setData(res.result);
          setAlertActiveQueryInfo((info) => {
            return { ...info, total: res.page.total as number };
          });
        }
      },
      onError: (err: any) => {
        setData([]);
        // setAlertActiveQueryInfo((info) => {
        //   return { ...info, total: 0 as number, pageNum: 0 };
        // });
      },
    }
  );
  const onRowClick = (row: ActiveAlert) => {
    // console.log(row.storageName, row.cameraName, row.cameraId, "rwo ---");
    setIsViewModal(!isViewModal);
    setSelectedAlert({
      type: row.recorderType,
      recorderId: row.recorderId,
      recorderName: row.recorderName,
      mergedSystemName: row.mergedSystemName,
      mergedSystemId: row.mergedSystemId,
      // alertType: row.alertTypeString,
      // camera: {
      //   channel: row.cameraChannel,
      //   cameraName: row.cameraName,
      //   cameraId: row.cameraId,
      // },
      // storageName: row.storageName,
      // category: row.type,
    });
  };
  const onChangeTable = (
    pagination: any,
    filters: any,
    sorter: SorterResult<ActiveAlert> | SorterResult<ActiveAlert>[]
    // extra: TableCurrentDataSource<any>
  ) => {
    // Ensure sorter is always an array
    // console.log(sorter);
    const sorters = Array.isArray(sorter) ? sorter : [sorter];

    sorters.forEach((sort) => {
      let sortOrder = sort.order;

      if (!sortOrder) {
        sortOrder = "ascend"; // You can set 'descend' as the default if you want
      }

      if (sort && "columnKey" in sort && "order" in sort) {
        setAlertActiveQueryInfo((info: BasePagination) => {
          return {
            ...info,
            sortType: sort.columnKey as string,
            sortDirection: changeSortOrderString(sortOrder as SortOrder),
          };
        });
      }
    });
  };
  const columns: TableColumnsType<ActiveAlert> = [
    commonOrgColumn(selectedAccount),
    {
      title: "Recorder",
      key: "recorderName",
      dataIndex: "recorderName",
      render: (value, row) => (
        <RecorderTitle
          mergedSystemName={row.mergedSystemName}
          recorderName={row.recorderName}
          type={row.recorderType}
        />
      ),
    },

    {
      title: "Alert",
      render: (value, row) => displayAlert(row),
      ellipsis: true,
    },
    {
      title: "Trigger Time",
      key: "alert_trigger",
      dataIndex: "alertTrigger",
      render: (value, row) =>
        value !== undefined
          ? getRecorderTimeFormat(value, selectedAccount, row.timezone, true)
          : "",
      sorter: true,
      // sortDirections: ["ascend", "descend"],
      showSorterTooltip: false,
      defaultSortOrder: "descend",
    },
    {
      title: "",
      render: (value, row) => (
        <UnderlineText
          onClick={(e) => {
            onRowClick(row);
          }}
          fontSize={14}
        >
          View
        </UnderlineText>
      ),
      width: 50,
      align: "right",
      // fixed: "right",
    },
  ];

  const onChangeTabMenu = (index: number) => {
    setCurrentMenu(index);
  };
  const onChangeAccount = (value: string[]) => {
    setAlertActiveQueryInfo((quer) => {
      return {
        ...quer,
        pageNum: 0,
      };
    });
    setAccountIds(value);
  };

  const onClickSearch = (keyword: string) => {
    setAlertActiveQueryInfo((quer) => {
      return {
        ...initialQueryInfo,
        keyword,
      };
    });
  };
  const onChangePage = useCallback(
    (page: number, totalRows: number) => {
      if (page !== alertActiveQueryInfo.pageNum + 1) {
        setAlertActiveQueryInfo((info) => {
          return { ...info, pageNum: (page - 1) as number };
        });
      }
    },
    [alertActiveQueryInfo.pageNum]
  );

  useEffect(() => {
    if (props.layout.h >= 2) {
      setAlertActiveQueryInfo((info) => {
        return {
          ...info,
          pageLimit: commonPaginationPerPage * props.layout.h,
        };
      });
    } else {
      setAlertActiveQueryInfo((info) => {
        return {
          ...info,
          pageLimit: commonPaginationPerPage,
        };
      });
    }
  }, [props.layout.h]);
  const params = new URLSearchParams({
    recorderId: selectedAlert?.recorderId ?? "",
    alertType: tabs[currentMenu].label,
    // historyId: selectedAlert?.historyId ?? "",
  });
  return (
    <>
      <TableItemHeader
        layout={props.layout}
        chartLayout={props.chartLayout}
        onDeleteWidget={props.onDeleteWidget}
        onLayoutChange={props.onLayoutChange}
        isError={isError}
      />
      <s.Contents className="drag-cancel-content">
        <s.ContentWrapper>
          <s.ContentHeader>
            <div>
              <ModalSubMenu
                menus={tabs}
                currentMenu={currentMenu}
                onChange={onChangeTabMenu}
              />
            </div>
            <div>
              <AccountSelects onChange={onChangeAccount} value={accountIds} />
              <SearchInput onClickSearch={onClickSearch} />
            </div>
          </s.ContentHeader>
          <s.ContentInner>
            <DashboardChartDetailTable
              dataSource={data}
              columns={columns}
              queryInfo={alertActiveQueryInfo}
              onChangePage={onChangePage}
              onChange={onChangeTable}
            />
          </s.ContentInner>
        </s.ContentWrapper>
      </s.Contents>
      {isViewModal && selectedAlert && (
        <AlertViewModal
          searchParams={params}
          onModal={() => setIsViewModal(!isViewModal)}
        />
      )}
    </>
  );
}
