import * as s from "./CvvStyled";
import {
  CVVPairItem,
  CvvItem,
  DayNightItem,
} from "api/interfaces/reportInterface.interface";
import {
  CenterlineText,
  Text,
  UnderlineText,
} from "components/atoms/text/Text";
import CvvLabel from "./CvvLabel";
import { COLORS } from "styles/colors";
import SmallLabel from "components/atoms/text/labels/SmallLabel";
import * as mediaQuery from "components/MediaQuery";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { getRecorderTimeFormat } from "utils/timeUtil";
import { isEmpty } from "lodash";
import { Option } from "utils/options";
import CVVThumbnail from "components/atoms/image/thumbnail/CVVThumbnail";
import {
  checkCVVError,
  checkCVVErrorBlocking,
  convertType,
} from "utils/functions";
import { Fragment, useCallback, useState } from "react";
import { CvvBase } from "./CvvContainer";
import BigPopupNoHeaderTemplate from "components/templates/modal/big/BigPopupNoHeaderTemplate";
import CLOSE_ICON from "assets/icons/sidenav/x.svg";
import WARNIN_CIRCLE_ICON from "assets/icons/warning_circle.svg";
import RBACWrapper from "components/blocks/function/RBACWrapper";

type Props = {
  data: DayNightItem;
  timezone: string;
  selectedAccount: ProfileAccountInfo;
  onClickUpdate: (data: DayNightItem, pairItem: CVVPairItem) => void;
  selectFilterRecorder: string;
  recorderList: Option[];
  recorderType?: string;
};

export default function CvvCamera({
  data,
  timezone,
  selectedAccount,
  onClickUpdate,
  selectFilterRecorder,
  recorderList,
  recorderType,
}: Props): JSX.Element {
  const cvvType = ["night", "day"];
  const cvvEvent = ["blur", "dust", "glare", "spider", "tampered"];

  const [isCvvOpen, setIsCvvOpen] = useState<boolean>(false);
  const [popupBase, setPopupBase] = useState<CvvBase>(CvvBase.REFERENCE);
  const [currentPairItem, setCurrentPairItem] = useState<CVVPairItem | null>(
    null
  );

  const onChangeOpen = useCallback(
    (data: DayNightItem, pairItem: CVVPairItem, base: CvvBase) => {
      if (pairItem !== undefined && pairItem !== null) {
        setPopupBase(base);
        setCurrentPairItem(pairItem);
        setIsCvvOpen(!isCvvOpen);
      }
    },
    [isCvvOpen]
  );

  const renderDataName = () => {
    if (!recorderType) {
      return `${data.name}`;
    }
    if (convertType(recorderType) === "SPECTRUM") {
      return `${data.name}`;
    }
    return `${data.name} #${data.channel + 1}`;
  };

  return (
    <>
      <s.CameraItem>
        <s.CameraTitle>
          <CenterlineText color="#828B9B" bold>
            {renderDataName()}
            {data.isCvv && (
              <span className="cvv-label">
                <CvvLabel status={data.isWarning ? "warning" : "normal"} />
              </span>
            )}
            {/* <span className="cvv-label">
            <CvvLabel />
          </span> */}
          </CenterlineText>
        </s.CameraTitle>

        <s.CameraWrapper>
          {cvvType.map((type, index) => {
            // event 에 하나라도 true 가 있으면 true
            const pairItem: CVVPairItem = data[type] as CVVPairItem;
            let isError = false;
            if (pairItem !== undefined) {
              isError = checkCVVError(pairItem.eventThumbnail);
            }
            // console.log(pairItem);

            // 추후 base, last [key] 값으로 구별 방식으로 리팩토링 필요
            return (
              <Fragment key={type}>
                <s.CameraThumbnail>
                  <s.ThumbnailImage
                    onClick={() =>
                      onChangeOpen(data, pairItem, CvvBase.REFERENCE)
                    }
                  >
                    {/* {isError && index === 1 && <s.ErrorBorder></s.ErrorBorder>} */}

                    <CVVThumbnail
                      errorStatus={
                        pairItem !== undefined &&
                        !isEmpty(pairItem.referenceThumbnail.errorStatus)
                          ? `${pairItem.referenceThumbnail.errorStatus}`
                          : undefined
                      }
                      videoLoss={false}
                      thumbnailUrl={
                        pairItem !== undefined &&
                        !isEmpty(pairItem.referenceThumbnail.url)
                          ? `${pairItem.referenceThumbnail.url}?v=${Date.now()}`
                          : undefined
                      }
                    />
                    {/* <img
                    src={pairItem !== undefined && !isEmpty(pairItem.referenceThumbnail.url) ? pairItem.referenceThumbnail.url : WARNING_CIRCLE_ICON}
                    alt="reference_thumbnail_night"
                  /> */}
                  </s.ThumbnailImage>
                  <mediaQuery.Default>
                    <div>
                      <Text className="timestamp">
                        {pairItem !== undefined &&
                          getRecorderTimeFormat(
                            pairItem.referenceThumbnail.date,
                            selectedAccount,
                            timezone,
                            true,
                            true
                          )}
                      </Text>
                      <div className="camera-title">
                        {index === 0 ? (
                          <Text color="#00A8FF" bold>
                            Reference Night
                          </Text>
                        ) : (
                          <Text color="#FF9D64" bold>
                            Reference Day
                          </Text>
                        )}

                        <RBACWrapper requiredPermissions="manageReportRules">
                          <UnderlineText
                            color={COLORS.BUTTONS1}
                            onClick={() => onClickUpdate(data, pairItem)}
                          >
                            Update
                          </UnderlineText>
                        </RBACWrapper>
                      </div>
                    </div>
                  </mediaQuery.Default>
                  <mediaQuery.Mobile>
                    <s.MobileCameraTitle>
                      {index === 0 ? (
                        <Text color="#00A8FF" bold>
                          Reference Night
                        </Text>
                      ) : (
                        <Text color="#FF9D64" bold>
                          Reference Day
                        </Text>
                      )}
                      <Text className="timestamp">
                        {pairItem !== undefined &&
                          getRecorderTimeFormat(
                            pairItem.referenceThumbnail.date,
                            selectedAccount,
                            timezone,
                            true,
                            true
                          )}
                      </Text>
                      <RBACWrapper requiredPermissions="manageReportRules">
                        <UnderlineText
                          color={COLORS.BUTTONS1}
                          className="update"
                          onClick={() => onClickUpdate(data, pairItem)}
                        >
                          Update
                        </UnderlineText>
                      </RBACWrapper>
                    </s.MobileCameraTitle>
                  </mediaQuery.Mobile>
                </s.CameraThumbnail>
                <s.CameraThumbnail>
                  <s.ThumbnailImage
                    onClick={() => onChangeOpen(data, pairItem, CvvBase.EVENT)}
                  >
                    {isError && <s.ErrorBorder></s.ErrorBorder>}
                    {isError && (
                      <s.ErrorWrapper>
                        {cvvEvent.map((evt, index) => {
                          if (pairItem.eventThumbnail[evt] === true) {
                            return (
                              <SmallLabel label={evt} color={COLORS.RED} />
                            );
                          } else return null;
                        })}
                      </s.ErrorWrapper>
                    )}
                    <CVVThumbnail
                      errorStatus={
                        pairItem !== undefined &&
                        !isEmpty(pairItem.eventThumbnail.errorStatus)
                          ? `${pairItem.eventThumbnail.errorStatus}`
                          : undefined
                      }
                      videoLoss={false}
                      thumbnailUrl={
                        pairItem !== undefined &&
                        !isEmpty(pairItem.eventThumbnail.url)
                          ? `${pairItem.eventThumbnail.url}?v=${Date.now()}`
                          : undefined
                      }
                    />
                    {/* <img src={pairItem !== undefined && !isEmpty(pairItem.eventThumbnail.url) ? pairItem.eventThumbnail.url : WARNING_CIRCLE_ICON} alt="" /> */}
                  </s.ThumbnailImage>
                  <div>
                    <Text className="timestamp">
                      {pairItem !== undefined &&
                        getRecorderTimeFormat(
                          pairItem.eventThumbnail.date,
                          selectedAccount,
                          timezone,
                          true,
                          true
                        )}
                    </Text>
                  </div>
                </s.CameraThumbnail>
              </Fragment>
            );
          })}
        </s.CameraWrapper>
      </s.CameraItem>
      {isCvvOpen && (
        <BigPopupNoHeaderTemplate>
          <s.ModalHeader>
            <s.ModalInfo>
              <s.CameraHeaderItem>
                <Text className="cvv-header-title">Recorders</Text>
                <Text bold>
                  {selectFilterRecorder !== undefined &&
                  selectFilterRecorder !== ""
                    ? recorderList.find(
                        (option) => option.value === selectFilterRecorder
                      )?.label
                    : ""}
                </Text>
              </s.CameraHeaderItem>

              <s.CameraHeaderItem>
                <Text className="cvv-header-title">Camera</Text>
                <Text bold>{renderDataName()}</Text>
              </s.CameraHeaderItem>

              <s.CameraHeaderItem>
                <Text className="cvv-header-title">Date / Time</Text>
                <Text bold>
                  {getRecorderTimeFormat(
                    popupBase === CvvBase.EVENT
                      ? currentPairItem!.eventThumbnail.date
                      : currentPairItem!.referenceThumbnail.date,
                    selectedAccount,
                    timezone,
                    true,
                    true
                  )}
                </Text>
              </s.CameraHeaderItem>
            </s.ModalInfo>

            <button onClick={() => setIsCvvOpen(false)}>
              <img alt="close button" src={CLOSE_ICON} />
            </button>
          </s.ModalHeader>
          <s.CvvContainer
            isError={
              popupBase === CvvBase.EVENT
                ? checkCVVError(currentPairItem!.eventThumbnail)
                : false
            }
          >
            {popupBase === CvvBase.EVENT &&
              checkCVVErrorBlocking(currentPairItem!.eventThumbnail) && (
                <s.CvvHeader>
                  <img src={WARNIN_CIRCLE_ICON} alt="warning circle icon" />
                  CVV has detected obstructions blocking the camera's view. For
                  optimal viewing quality, please remove them as soon as
                  possible.
                </s.CvvHeader>
              )}

            <s.CvvThumbnailWrapper>
              <s.ErrorWrapper>
                {popupBase === CvvBase.EVENT &&
                  cvvEvent.map((evt, index) => {
                    if (
                      data !== null &&
                      currentPairItem!.eventThumbnail[evt] === true
                    ) {
                      return <SmallLabel label={evt} color={COLORS.RED} />;
                    } else return null;
                  })}
              </s.ErrorWrapper>
              <CVVThumbnail
                errorStatus={
                  currentPairItem !== undefined &&
                  popupBase === CvvBase.REFERENCE
                    ? !isEmpty(currentPairItem!.referenceThumbnail.errorStatus)
                      ? `${currentPairItem!.referenceThumbnail.errorStatus}`
                      : undefined
                    : !isEmpty(currentPairItem!.eventThumbnail.errorStatus)
                    ? `${currentPairItem!.eventThumbnail.errorStatus}`
                    : undefined
                }
                videoLoss={false}
                thumbnailUrl={`${
                  popupBase === CvvBase.EVENT
                    ? currentPairItem!.eventThumbnail.url
                    : currentPairItem!.referenceThumbnail.url
                }?v=${Date.now()}`}
              />
            </s.CvvThumbnailWrapper>
          </s.CvvContainer>
        </BigPopupNoHeaderTemplate>
      )}
    </>
  );
}
