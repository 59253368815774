import { DataRow as SystemDataRow } from "./ListContainer";
import { DataRow as SystemChildDataRow } from "../childrenList/ChildrenListContainer";
import * as s from "../RecordersStyled";
import { useState } from "react";
import { AuthErrorHelp, DetachedHelp, IncompleteRegistrationHelp } from "../RecorderAlertHelp";
import { WithTooltip } from "components/blocks/WithTooltip";
import { RiAlertFill } from "react-icons/ri";
import { COLORS } from "styles/colors";
import styled from "styled-components";
import { IntlShape, useIntl } from "react-intl";

const alertTooltipMessageId = "label.recorder.tooltip.alert.instruction";

const getIntl = (intl: IntlShape, id: string): string => {
  return intl.formatMessage({ id });
}

type NeedActionCellTye = {
  recorder: SystemDataRow & SystemChildDataRow;
}

export const NeedActionCell = ({ recorder }: NeedActionCellTye): JSX.Element => {
  const { hasAuthError, hasDetached, isDetached, hasIncompleteRegistration } = recorder;

  if (!hasAuthError && !hasDetached && !isDetached && !hasIncompleteRegistration) {
    return <></>
  }

  return (
    <s.Row>
      <Cell>
        {hasAuthError && <AuthErrorAlert />}
        {hasIncompleteRegistration && <IncompletRegistrationAlert />}
        {hasDetached && <DetachedAlert />}
        {isDetached && <DetachedAlert />}
      </Cell>
    </s.Row>
  );
}

const AuthErrorAlert = () => {
  const intl = useIntl();
  const [isHelpVideo, setIsHelpVideo] = useState<boolean>(false);
  const openHelpVideo = () => {
    setIsHelpVideo(true);
  };
  const closeHelpVideo = () => {
    setIsHelpVideo(false);
  }
  const tooltipMessage = getIntl(intl, alertTooltipMessageId);

  return (
    <AlertItem>
      <WithTooltip tooltip={tooltipMessage}>
        <AlertItemText onClick={openHelpVideo}>
          <RiAlertFill /> Admin Password
        </AlertItemText>
      </WithTooltip>
      {isHelpVideo && (
        <AuthErrorHelp onModal={closeHelpVideo} />
      )}
    </AlertItem>
  )
}

const DetachedAlert = () => {
  const intl = useIntl();
  const [isHelpMessage, setIsHelpMessage] = useState<boolean>(false);
  const openHelpMessage = () => {
    setIsHelpMessage(true);
  };
  const closeHelpMessage = () => {
    setIsHelpMessage(false);
  }

  const tooltipMessage = getIntl(intl, alertTooltipMessageId);

  return (
    <AlertItem>
      <WithTooltip tooltip={tooltipMessage}>
        <AlertItemText onClick={openHelpMessage}>
          <RiAlertFill /> Detached
        </AlertItemText>
      </WithTooltip>
      {isHelpMessage && (
        <DetachedHelp onModal={closeHelpMessage} />
      )}
    </AlertItem>
  );
}

const IncompletRegistrationAlert = () => {
  const intl = useIntl();
  const [isHelpVideo, setIsHelpVideo] = useState<boolean>(false);
  const openHelpVideo = () => {
    setIsHelpVideo(true);
  };
  const closeHelpVideo = () => {
    setIsHelpVideo(false);
  }

  const tooltipMessage = getIntl(intl, alertTooltipMessageId);

  return (
    <AlertItem>
      <WithTooltip tooltip={tooltipMessage}>
        <AlertItemText onClick={openHelpVideo}>
          <RiAlertFill /> Incomplete Registration
        </AlertItemText>
      </WithTooltip>
      {isHelpVideo && (
        <IncompleteRegistrationHelp onModal={closeHelpVideo} />
      )}
    </AlertItem>
  )
}

const Cell = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  border-radius: 2px;
  color: ${COLORS.WARNING};
  background: ${COLORS.WARNING}05;
  background: ${({ theme }) =>
    theme.value === "light"
      ? `linear-gradient(90deg, ${COLORS.WARNING}20 0%, ${COLORS.WARNING}00 100%)`
      : `linear-gradient(90deg, ${COLORS.WARNING}30 0%, ${COLORS.WARNING}00 100%)`};
`;

const AlertItem = styled.div`
  padding: 1px 2px;
  border-radius: 2px;
  padding-right: 2px;
  transition: all ease-out 0.1s;

  &:hover {
    background: ${COLORS.WARNING}25;
  }
`;

const AlertItemText = styled.div`
  display: flex;
  gap: 4px;
  font-weight: 300;
`;
