import styled from "styled-components";
import { COLORS, NOTICE_COLORS } from "styles/colors";
import { FLEX } from "styles/flex";
import { calcRem } from "styles/theme";
import * as mediaQuery from "components/MediaQuery";

const Container = styled.div<{ type: RecorderType }>`
  border-radius: 2px;

  font-size: ${calcRem(14)};
  color: ${COLORS.WHITE};
  ${FLEX.FlexCenterCenter};
  background-color: ${COLORS.WHITE}40;

  ${({ type }) =>
    (type.includes("NVR") || type.includes("VMAX")) &&
    `
  background-color: ${COLORS.PRIMARY1};
`}
  ${({ type }) =>
    type.toUpperCase().includes("SPECTRUM") &&
    `
    background-color: #175B22;
  `};

  ${({ type }) =>
    type.toUpperCase().includes("COVA") &&
    `
    background-color: ${NOTICE_COLORS.PURPLE};
  `};

  ${mediaQuery.isDefault} {
    min-width: 90px;
    height: 28px;
  }
  ${mediaQuery.isMobile} {
    padding: 6px 8px;
  }
`;

interface TypeProps {
  type: RecorderType;
}

export type RecorderType =
  | "COVA"
  | "VMAX-IP"
  | "VMAX-A1"
  | "NVR"
  | "SPECTRUM"
  | "UNKNOWN";

function Type({ type }: TypeProps): JSX.Element {
  return (
    <Container type={type} className="recorder-type" data-tag="allowRowEvents">
      {type}
    </Container>
  );
}

export default Type;
