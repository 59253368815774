import { ReportTable } from "components/atoms/table/Table";
import { Media, TableColumn } from "react-data-table-component";
import TableButton from "components/atoms/buttons/TableButton";
import {
  ReportHealthDetailRecorder,
  ReportHealthDetailRecorderAlert,
} from "api/interfaces/reportInterface.interface";
import { useAppSelector } from "redux/hooks";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { getRecorderTimeFormat } from "utils/timeUtil";

import { RecorderDetail } from "../../HealthDetail";
import { useLocation, useNavigate } from "react-router-dom";
import { navigateAlertDetailPage } from "utils/AlertUtil";

type Props = {
  data: ReportHealthDetailRecorder;
  recorderInfo: RecorderDetail;
};

export default function Health({ data, recorderInfo }: Props): JSX.Element {
  const location = useLocation();
  const navigate = useNavigate();

  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );

  const columns: TableColumn<ReportHealthDetailRecorderAlert>[] = [
    {
      name: "Type",
      grow: 1,
      selector: (row) => row.type,
    },
    {
      name: "Alert",
      grow: 1.5,
      selector: (row) =>
        row.alertReason !== "Video Loss"
          ? row.alertReason
          : `${row.alertReason} [${row.cameraName}]`,
      // ? `${row.alertReason} [${row.cameraName}]`
      // : `${row.alertReason} [CH${row.cameraChannel + 1})]`,
    },
    {
      name: "Status",
      grow: 1,
      selector: (row) => row.status,
    },
    {
      id: "eventDate",
      name: "Event Time",
      grow: 2,
      selector: (row) =>
        row.eventDate !== undefined
          ? getRecorderTimeFormat(
              row.eventDate,
              selectedAccount,
              row.timezone,
              true
            )
          : "",
      hide: Media.SM,
    },
    {
      name: "Alert Trigger Time",
      grow: 2,
      selector: (row) =>
        row.alertTrigger !== undefined
          ? getRecorderTimeFormat(
              row.alertTrigger,
              selectedAccount,
              row.timezone,
              true
            )
          : "",
      hide: Media.SM,
    },
    {
      name: "Rule",
      grow: 1.5,
      selector: (row) => row.alertName,
      hide: Media.SM,
    },
    // 마지막 버튼
    {
      name: "",
      maxWidth: "80px",
      cell: (row) => (
        <TableButton label="View" onClickBtn={() => onRowClick(row)} />
      ),
      right: true,
    },
  ];

  const onRowClick = (row: ReportHealthDetailRecorderAlert) => {
    navigateAlertDetailPage({
      navigate,
      recorderId: recorderInfo.recorderId,
      historyId: row.alertHistoryId,
      alertType: row.type,
    });
  };
  return (
    <ReportTable
      data={data.alerts}
      columns={columns}
      paginationPerPage={10}
      onRowClicked={onRowClick}
    />
  );
}
